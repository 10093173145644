import { Component, OnInit } from '@angular/core';
import { Gallery } from 'ng-gallery';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-projects',
    templateUrl: 'team-fadi-projects.component.html',
    styleUrls: ['team-fadi-projects.component.scss']
})
export class TeamFadiProjectsComponent implements OnInit {

    ngOnInit() { }

    // <!-- Navigation Start-----------------------------------------------Navigation End------ -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    // <!-- Header Start----------------------------------------------------------------------- -->
    headercarousel = [
        {
          img: 'assets/images/team-fadi/Banners/project.jpg',
          tagLine1:"View Our",
          tagLine2:"Work",
          description1:"Explore our successful campaigns and projects",
        //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
        //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
        },
      ]
      
      headercarouselOptions= {
        items: 1,
        margin: 0,
        autoHeight: true,
        nav: false,
        autoplay: false,
        slideSpeed: 200,
        paginationSpeed: 400,
        loop: true,
      }
    // <!-- Header End------------------------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    // <!-- all project start------------------------------------------------------------------ -->
    activeFilter = 'all-img'
    private urlsAll: string[] = [
        'assets/images/team-fadi/allproject/project-01.jpg',
        'assets/images/team-fadi/allproject/project-02.jpg',
        'assets/images/team-fadi/allproject/project-03.jpg',
        'assets/images/team-fadi/allproject/project-04.jpg',
        'assets/images/team-fadi/allproject/project-05.jpg',
        'assets/images/team-fadi/allproject/project-06.jpg',
        'assets/images/team-fadi/allproject/project-07.jpg',
        'assets/images/team-fadi/allproject/project-09.jpg',
        'assets/images/team-fadi/allproject/project-10.jpg',
        'assets/images/team-fadi/allproject/project-11.jpg',
    ];

    private urlsBranding: string[] = [
        'assets/images/team-fadi/branding/branding2.png',
    ];

    private urlsBranding1: string[] = [
        'assets/images/team-fadi/branding/branding9.jpg',
    ];

    private urlsBranding2: string[] = [
        'assets/images/team-fadi/branding/branding12.jpg',
    ];

    private urlsFascia: string[] = [
        'assets/images/team-fadi/fascia/fascia2.jpg',
    ];

    private urlsFascia1: string[] = [
        'assets/images/team-fadi/fascia/fascia5.png',

    ];

    private urlsStallFabrication: string[] = [
        'assets/images/team-fadi/stalls/stall1.jpg',
    ];
    private urlsStallFabrication1: string[] = [,
        'assets/images/team-fadi/stalls/stall6.jpg',
    ];

    private urlsEvents: string[] = [
        'assets/images/team-fadi/events/event1.jpg',
    ];
    private urlsEvents1: string[] = [
        'assets/images/team-fadi/events/event5.jpg',
    ];
    private urlsEvents2: string[] = [
        'assets/images/team-fadi/events/event12.png',
    ];



    public filterImg(item) {
        this.activeFilter = item
    }
    // <!-- all project end-------------------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    // <!--  Footer Start-------------------------------------------------------Footer End----- -->
    /////////////////////////////////////////////////////////////////////////////////////////// -->
    // <!--  Copyright start-------------------------------------------------Copyright End----- -->
}
