import { Component, OnInit } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeInLeft } from 'ng-animate';

@Component({
  moduleId: module.id,
  selector: 'team-fadi-services',
  templateUrl: 'team-fadi-services.component.html',
  styleUrls: ['team-fadi-services.component.scss'],
  animations: [
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 3, delay: 1 }
    }))])
  ],
})
export class TeamFadiServicesComponent implements OnInit {

  fadeInLeft: any;

  constructor() { }

  ngOnInit() {
  }
  // <!-- Navigation Start-------------------------------------------------------Navigation End---- -->
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  // <!-- Header Start----------------------------------------------------------------------------- -->
  headercarousel = [
    {
      img: 'assets/images/team-fadi/Banners/services.jpg',
      tagLine1:"WHAT WE",
      tagLine2:"OFFER",
      description1:"- Welcome to TEAM FADI – Your Partner in Success!",
    //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
    //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
    },
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 200,
    paginationSpeed: 400,
    loop: true,
  }
  // <!-- Header End-------------------------------------------------------------------------------- -->
  // <!-- Footer Start----------------------------------------------------------------Footer End---- -->
  // <!-- Copyright Start-------------------------------------------------------------Copyright End- -->
}
