<!-- section start -->
<section class="agency blog blog-sec blog-sidebar blog-split">
    <div class="container">
        <div class="row pl-0 pr-0">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-12 blog-sec blog-list"  *ngFor="let blog of blogData">
                        <div class="blog-agency">
                            <div class="row blog-contain pl-0 pr-0">
                                <div class="col-xl-3 col-lg-4 col-md-6 p-0" [ngClass]="blog.Id%2==0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img [src]="blog.img" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-9 col-lg-8 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <div class="m-b-20">
                                                    <div class="center-text">
                                                        <i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                                                        <h6 class="m-r-25 font-blog">{{blog.date}}</h6>
                                                        <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                                                        <h6 class="font-blog">{{blog.type}}</h6>
                                                    </div>
                                                </div>
                                                <h5 class="blog-head font-600">{{blog.title}}</h5>
                                                <p class="para2">{{blog.content}}</p>
                                                <div class="btn-bottom m-t-20">
                                                    <a href="#" class="text-uppercase">{{blog.btn}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end-->
