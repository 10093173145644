import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { fadeInRight } from 'ng-animate';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-about',
    templateUrl: 'team-fadi-about.component.html',
    styleUrls: ['team-fadi-about.component.scss'],
    animations: [
      trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight))])]
})
export class TeamFadiAboutComponent implements OnInit {
  fadeInRight: any;

    constructor() { }

    ngOnInit() {
    }



    // <!-- Navigation Start------------------------------------------------- Navigation End--- -->
    ///////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- Header Start----------------------------------------------------------------------- -->
    headercarousel = [
      {
        img: 'assets/images/team-fadi/Banners/about_us.jpg',
        tagLine1:"Who We Are",
        tagLine2:"TEAM FADI",
        description1:"- Welcome to TEAM FADI – Your Partner in Success!",
      //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
      //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
      },
    ]
    
    headercarouselOptions= {
      items: 1,
      margin: 0,
      autoHeight: true,
      nav: false,
      autoplay: false,
      slideSpeed: 200,
      paginationSpeed: 400,
      loop: true,
    }
      // <!-- Header End------------------------------------------------------------------------ -->
      //////////////////////////////////////////////////////////////////////////////////////////////
      // <!-- what we do Start ----------------------------------------------------------------- -->
        users1 = [
          { 
            img:"assets/images/team-fadi/about.jpg"
          },
          {
            img:"assets/images/team-fadi/about.jpg"
          }
        ]
        
        testimonialCarouselOptions= {
          items: 1,
          margin: 0,
          dots: true,
          nav: false,
          autoplay: false,
          slideSpeed: 300,
          paginationSpeed: 400,
          loop: true
        }
    // <!-- what we do End ----------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // feather start ----------------------------------------------------------------------------- -->
    users2 = [
      { 
        img:"assets/images/team-fadi/about.jpg"
      },
      {
        img:"assets/images/team-fadi/about.jpg"
      }
    ]
    
    testimonialCarouselOptions1= {
      items: 1,
      margin: 0,
      dots: true,
      nav: false,
      autoplay: true,
      slideSpeed: 300,
      paginationSpeed: 400,
      loop: true
    }
    // feather end ------------------------------------------------------------------------------- --> 
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Footer Start------------------------------------------------------------Footer End--- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start------------------------------------------------------Copyright End--- -->
}
