<section class="p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>subscribe 1</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="app1 subscribe bg" [ngStyle]="{'background-image': 'url(assets/images/app_landing1/subscribe/subscribe.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="flex">
                                <input type="email" class="form-control " placeholder="Please Enter Your Email Address"
                                       name="email">
                                <div class="button-primary">
                                    <button type="submit"
                                            class=" btn btn-default btn-gradient text-white text-uppercase">subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="socials-lists m-t-50">
                    <ul class="socials-horizontal justify-content-center">
                        <li>
                            <a href="#" class=""><i class="fa fa-facebook center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="#" class=""><i class="fa fa-twitter center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="#" class=""><i class="fa fa-google center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-instagram center-content" aria-hidden="true"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>subscribe 2</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="contact" class="saas1 subscribe">

    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="media">
                    <img class="" src="assets/images/saas1/subscribe-icon.png" alt="profile">
                    <div class="media-body">
                        <h3 class="mt-0 text-white">Start 30 days free trial</h3>
                        <h6 class="text-white">Our users are impatient. They're probably distracted too. Keep it
                            simple and beautiful, fun and functional.
                        </h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="subscribe-input">
                    <form>
                        <input id="useremail" type="email" placeholder="This Field is Required" required="">
                        <input id="submit" type="submit" value="Get Started">
                    </form>
                    <div class="text text-center d-flex">
                        <h6 class="text-white">Free 30-day trial</h6><h6 class="text-white">Easy setup</h6><h6
                            class="text-white">Cancel any time</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>subscribe 3</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="event subscribe subscribe-3 set-relative p-t-0">
    <div class="circle">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="flex">
                                <input type="email" class="form-control text-capitalize"
                                       placeholder="Please Enter Your Email Address" name="email">
                                <div class="button-primary">
                                    <button type="submit"
                                            class=" btn btn-default primary-btn text-white text-capitalize">
                                        contact me
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>