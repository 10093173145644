<!-- Navigation bar Start--------------------------------------------------------------------- -->
<header class="gym loding-header nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(0 0 0 / 90%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="https://teamfadi.com/" class="d-inline-block m-r-auto">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="logo" class="img-fluid" style="width: 100px; margin-left: 150px;">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End--------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start--------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
            <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel">
                        <ng-template carouselSlide class="item">
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url('+ header.img +')'}" style="background-position: center; background-size: cover; object-fit: cover;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                            <h1>
                                                <span class="bold-text">{{header.tagLine1}}
                                                <br> &nbsp;&nbsp;&nbsp;&nbsp; {{header.tagLine2}} </span>
                                            </h1>
                                        </div>
                                        <div class="header-sub-text">
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description1}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description2}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description3}}</p>
                                        </div>
                                        <div class="link-horizontal">
                                            <ul class="justify-content-center">
                                                <li>
                                                    <a class="btn btn-default btn-gradient text-white"
                                                    style="padding-left: 50px; padding-right: 50px;">start now</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
                </ng-container>
                </owl-carousel-o>
              </div>
</section>
<!-- Header End----------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Our blog start------------------------------------------------------------------------- -->
<section class="agency blog blog-sec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="blog-agency">
                    <div class="blog-contain">
                        <img src="assets/images/team-fadi/web.webp" alt="" class="img-fluid" style="background-position: center;
                        background-size: cover; object-fit: cover;">
                        <div class="img-container center-content">
                            <div class="center-content">
                                <div class="blog-info">
                                    <div class="m-b-20">
                                        <div class="center-text"><i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                                            <h6 class="m-r-25 font-blog">June 19, 2018</h6>
                                            <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                                            <h6 class="font-blog">Phonics ,Newyork</h6>
                                        </div>
                                    </div>
                                    <h5 class="blog-head font-600">Twice profit than before you</h5>
                                    <p class="para2">Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the
                                        1500s, when an unknown printer took a galley of type and scrambled it to make a
                                        type specimen book...............</p>
                                    <div class="btn-bottom m-t-20"><a href="#" class="text-uppercase">read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6" *ngFor="let blog of blogData">
                <div class="blog-agency">
                    <div class="blog-contain">
                        <img [src]="blog.img" alt="" class="img-fluid">
                        <div class="img-container center-content">
                            <div class="center-content">
                                <div class="blog-info">
                                    <div class="m-b-20">
                                        <div class="center-text"><i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                                            <h6 class="m-r-25 font-blog">{{blog.date}}</h6>
                                            <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                                            <h6 class="font-blog">{{blog.type}}</h6>
                                        </div>
                                    </div>
                                    <h5 class="blog-head font-600">{{blog.title}}</h5>
                                    <p class="para2">{{blog.content}}</p>
                                    <div class="btn-bottom m-t-20"><a href="#" class="text-uppercase">{{blog.btn}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Our blog end--------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start-------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">TEAM FADI</h6>
                            <h6 class="text-white para-address">Office No# 123, Second Floor, </h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">services</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">projects</a>
                            </li>
                            <li class="">
                                <a routerLink="Clients">Clients</a>
                            </li>
                            <li class="">
                                <a routerLink="Contactus">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">Billboards</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Corporate Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Stall Fabrication</a>
                            </li>
                            <li class="">
                                <a routerLink="services"> Digital Marketing</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Acrylic Logo Execution</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="projects">Complete Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Shop Fascia</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Confrence Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Stall Fabrication</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Copyright Start----------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- ---------------------------------------The End----------------------------------------- -->