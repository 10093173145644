import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-project-kia',
    templateUrl: 'team-fadi-project-kia.component.html',
    styleUrls: ['team-fadi-project-kia.component.scss']
})
export class TeamFadiProjectKiaComponent {

    constructor() { }

    ngOnInit() {
    }
  
  
  
    // <!-- Navigation Start------------------------------------------------------Navigation End----- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- Header Start----------------------------------------------------------------------------- -->
    headercarousel = [
      {
        img: 'assets/images/team-fadi/Banners/project.jpg',
        tagLine1:"KIA Motors",
        // tagLine2:" Architects",
        description1:"- Conference Branding Event!",
      //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
      //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
      },
    ]
    
    headercarouselOptions= {
      items: 1,
      margin: 0,
      autoHeight: true,
      nav: false,
      autoplay: false,
      slideSpeed: 200,
      paginationSpeed: 400,
      loop: true,
    }
    // <!-- Header End-------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- client start------------------------------------------------------------------------------- -->
    client = [
      {
        img: 'assets/images/team-fadi/events/event12.png',
      },
      {
        img: 'assets/images/team-fadi/events/event11.jpg',
      },
      {
        img: 'assets/images/team-fadi/events/event10.png',
      },
      {
        img: 'assets/images/team-fadi/events/event9.jpg',

      },
      {
        img: 'assets/images/team-fadi/events/event8.png',
      }, 
      {
        img: 'assets/images/team-fadi/events/event13.jpg',
      },
      
    ]
    // <!-- client End-------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Footer Start---------------------------------------------------------------Footer End---- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start------------------------------------------------------------Copyright End- -->


}
