import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'team-fadi',
    templateUrl: 'team-fadi.component.html',

})
export class TeamFadiComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }



    // <!-- Navigation Start-------------------------------------------------------------------------------------- -->
    // <!-- Navigation End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!-- Header Start-------------------------------------------------------------------------------------- -->
    headercarousel = [
        {
            tagLine1: "SIMPLIFY THE BRANDING",
            tagLine2: "FOR YOUR COMPANY",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
        },
        {
            tagLine1: "build your",
            tagLine2: "body more strong",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."
        },
    ]

    headercarouselOptions = {
        items: 1,
        margin: 0,
        autoHeight: true,
        nav: false,
        autoplay: false,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
    }
    // <!-- Header End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!-- About Start-------------------------------------------------------------------------------------- -->
    // <!-- About End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!-- Schdule Start-------------------------------------------------------------------------------------- -->
    schedulecarousel = [
        {
            tagLine1: "#1 Day Training",
            tagLine2: "Monday",
            description1: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
            description2: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
        },
        {
            tagLine1: "#1 Day Training",
            tagLine2: "Monday",
            description1: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
            description2: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
        },
    ]

    schedulecarouselOptions = {
        items: 1,
        margin: 0,
        dots: true,
        nav: false,
        autoplay: false,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
    }
    // <!-- Schdule End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Workout Start-------------------------------------------------------------------------------------- -->
    // <!--  Workout End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Counter Start-------------------------------------------------------------------------------------- -->
    counter = [
        {
            count: '100',
            img: 'assets/images/gym/counter/happy-icon.png',
            type: 'Satisfied Customers'
        },
        {
            count: '120',
            img: 'assets/images/gym/counter/project-icon.png',
            type: 'Projects Completed'
        },

        {
            count: '40',
            img: 'assets/images/gym/counter/award-icon.png',
            type: 'Awwards Winning'
        },
        {
            count: '1510',
            img: 'assets/images/gym/counter/work-icon.png',
            type: 'Hours Worked'
        },
    ]
    // <!--  Counter End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Trainer Start-------------------------------------------------------------------------------------- -->
    trainercarousel = [
        {
            img: "assets/images/gym/traniers/1.jpg",
            title:"asdasdasd"
        },
        {
            img: "assets/images/gym/traniers/2.jpg"
        },
        {
            img: "assets/images/gym/traniers/3.jpg"
        },
        {
            img: "assets/images/gym/traniers/4.jpg"
        },
        {
            img: "assets/images/gym/traniers/5.jpg"
        },
        {
            img: "assets/images/gym/traniers/3.jpg"
        }
    ]

    trainercarouselOptions = {
        center: true,
        loop: true,
        dots: true,
        margin: 0,
        responsive: {
            0: {
                items: 2,
                margin: 10
            },
            576: {
                items: 3
            },
            768: {
                items: 4
            },
            1200: {
                items: 6
            }
        }
    }
    // <!--  Trainer End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Testimonial Start-------------------------------------------------------------------------------------- -->
    users = [
        {
            img: "assets/images/gym/testimonial/quote.png"
        },
        {
            img: "assets/images/gym/testimonial/quote.png"
        }
    ]

    testimonialCarouselOptions = {
        items: 1,
        margin: 0,
        dots: true,
        nav: false,
        autoplay: false,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true
    }
    // <!--  Testimonial End---------------------------------------------------------------------------------------- -->
    
    about=[
        {
          name:'Photoshop',
          timer:'95%',
          exp:'4 Years Experince'
        },
        {
          name:'illustrator',
          timer:'95%',
          exp:'4 Years Experince'
        },
        {
          name:'html & css',
          timer:'95%',
          exp:'4 Years Experince'
        },
        {
          name:'wordpress',
          timer:'95%',
          exp:'4 Years Experince'
        }
      ]


      service = [
        {
          img:"assets/images/resume/service-exprience/1.png",
          type:'UI Design'
        },
        {
          img:"assets/images/resume/service-exprience/2.png",
          type:'UX Design'
        },
        {
          img:"assets/images/resume/service-exprience/3.png",
          type:'Web Design'
        },
        {
          img:"assets/images/resume/service-exprience/4.png",
          type:'Web Design'
        },
        {
          img:"assets/images/resume/service-exprience/5.png",
          type:'Web Design'
        },
        {
          img:"assets/images/resume/service-exprience/6.png",
          type:'Web Devloper'
        },
        {
          img:"assets/images/resume/service-exprience/7.png",
          type:'Web Design'
        },
        {
          img:"assets/images/resume/service-exprience/8.png",
          type:'Web Design'
        }
       ]
    
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Pricing Start-------------------------------------------------------------------------------------- -->
    pricing = [
        {
            icon: "rocket-ship",
            package: "free",
            feature1: "Easy Installations",
            feature2: "Unlimited support",
            feature3: "Free Forever",
            price: "0",
            btn: "purchase"
        },
        {
            icon: "diamond",
            package: "Gold Plan",
            feature1: "Easy Installations",
            feature2: "Unlimited support",
            feature3: "Free Forever",
            price: "49",
            btn: "purchase"
        },
        {
            icon: "pie-chart",
            package: "Silver Plan",
            feature1: "Easy Installations",
            feature2: "Unlimited support",
            feature3: "Free Forever",
            price: "99",
            btn: "purchase"
        }
    ]

    pricingcarouselOptions = {
        items: 3,
        margin: 30,
        nav: false,
        autoplay: false,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        dots: false,
        responsive: {
            0: {
                items: 1,
                margin: 10
            },
            480: {
                items: 2,
                margin: 10
            },
            992: {
                items: 3,
                margin: 10
            }
        }
    }
    // <!--  Pricing End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  BMI Start-------------------------------------------------------------------------------------- -->
    // <!--  BMI End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Brand Start-------------------------------------------------------------------------------------- -->
    brands = [
        { img: "assets/images/gym/brand/1.jpg" },
        { img: "assets/images/gym/brand/2.jpg" },
        { img: "assets/images/gym/brand/3.jpg" },
        { img: "assets/images/gym/brand/4.jpg" },
        { img: "assets/images/gym/brand/1.jpg" },
        { img: "assets/images/gym/brand/2.jpg" }
      ]
      
      brandcarouselOptions= {
        items: 6,
        margin: 50,
        autoHeight: true,
        nav: false,
        dots: false,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        responsive: {
            0: {
                items: 2,
                margin: 10
            },
            320: {
                items: 3,
                margin: 10
            },
            480:{
                items: 4,
                margin: 30
            },
            768:{
                items: 5,
                margin: 50
            },
            1200: {
                items: 6,
                margin: 50
            }
        }
      }
    // <!--  Brand End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Footer Start-------------------------------------------------------------------------------------- -->
    // <!--  Footer End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Copyright Start-------------------------------------------------------------------------------------- -->
    // <!--  Copyright End---------------------------------------------------------------------------------------- -->

}
