import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-schedule',
  templateUrl: './event-schedule.component.html',
  styleUrls: ['./event-schedule.component.scss']
})
export class EventScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
