import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-project-shan',
    templateUrl: 'team-fadi-project-shan.component.html',
    styleUrls: ['team-fadi-project-shan.component.scss']
})
export class TeamFadiProjectShanComponent {

    constructor() { }

    ngOnInit() {
    }
  
  
  
    // <!-- Navigation Start------------------------------------------------------Navigation End----- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- Header Start----------------------------------------------------------------------------- -->
    headercarousel = [
      {
        img: 'assets/images/team-fadi/Banners/project.jpg',
        tagLine1:"Shan Foods",
        // tagLine2:" Architects",
        description1:"- Shan Foods Stall Fabrication at Expo Centre KHI",
      //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
      //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
      },
    ]
    
    headercarouselOptions= {
      items: 1,
      margin: 0,
      autoHeight: true,
      nav: false,
      autoplay: false,
      slideSpeed: 200,
      paginationSpeed: 400,
      loop: true,
    }
    // <!-- Header End-------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- client start------------------------------------------------------------------------------- -->
    client = [
      {
        img:'assets/images/team-fadi/stalls/stall1.jpg',
      },
      {
        img:'assets/images/team-fadi/stalls/stall2.jpg',
      },
      {
        img:'assets/images/team-fadi/stalls/stall3.jpg',
      },
      {
        img:'assets/images/team-fadi/stalls/stall4.jpg',
      },
      {
        img:'assets/images/team-fadi/stalls/stall5.jpg',
      },
    ]
    // <!-- client End-------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Footer Start---------------------------------------------------------------Footer End---- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start------------------------------------------------------------Copyright End- -->

}
