<!-- Login-popup section start -->
<section class="login-popup">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="text-center">
                    <h2 class="m-b-20">click here for the Modal popup</h2>
                    <a class="popup-with-form btn btn-default primary-btn" (click)="openVerticallyCentered(content)">click here
                    </a>
                    <ng-template #content let-modal>
                            <div class="login-modal">
                                                <div class="modal-header">
                                                    <button aria-label="Close" (click)="modal.dismiss('Cross click')" class="close" data-dismiss="modal" type="button">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body text-center">
                                                    <h3 class="m-b-20">modal description</h3>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                                        the industry's standard dummy text ever since the 1500s.</p>
                                                    <a aria-label="Close" class="btn btn-default primary-btn m-t-20" data-dismiss="modal">close
                                                    </a>
                                                </div>
                                            </div>
                </ng-template>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Login-popup section end -->
