import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamFadiIndexComponent } from './team-fadi-index/team-fadi-index.component';
import { TeamFadiAboutComponent } from './team-fadi-about/team-fadi-about.component';
import { TeamFadiServicesComponent } from './team-fadi-services/team-fadi-services.component';
import { TeamFadiProjectsComponent } from './team-fadi-projects/team-fadi-projects.component';
import { TeamFadiBlogsComponent } from './team-fadi-blogs/team-fadi-blogs.component';
import { TeamFadiClientsComponent } from './team-fadi-clients/team-fadi-clients.component';
import { TeamFadiContactusComponent } from './team-fadi-contactus/team-fadi-contactus.component';
import { TeamFadiProjectShoopComponent } from './team-fadi-project-shoop/team-fadi-project-shoop.component';
import { TeamFadiProjectMonkeychunkeyComponent } from './team-fadi-project-monkeychunkey/team-fadi-project-monkeychunkey.component';
import { TeamFadiProjectFedexComponent } from './team-fadi-project-fedex/team-fadi-project-fedex.component';
import { TeamFadiProjectShanComponent } from './team-fadi-project-shan/team-fadi-project-shan.component';
import { TeamFadiProjectKiaComponent } from './team-fadi-project-kia/team-fadi-project-kia.component';



const routes: Routes = [
  {
    path: 'index',
    component: TeamFadiIndexComponent,
    data: {
      title: "Index | Team Fadi",
      name: "Description",
      content: " record keeping."
    }
   },
   {
    path: 'about',
    component: TeamFadiAboutComponent,
    data: {
      title: "About | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
  
   {
    path: 'services',
    component: TeamFadiServicesComponent,
    data: {
      title: "service | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   
   {
    path: 'projects',
    component: TeamFadiProjectsComponent,
    data: {
      title: "projects | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   
   {
    path: 'blogs',
    component: TeamFadiBlogsComponent,
    data: {
      title: "blogs | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   
   {
    path: 'clients',
    component: TeamFadiClientsComponent,
    data: {
      title: "clints | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   
   {
    path: 'contactus',
    component: TeamFadiContactusComponent,
    data: {
      title: "contactus | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   {
    path: 'projectshoop',
    component: TeamFadiProjectShoopComponent,
    data: {
      title: "contactus | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   {
    path: 'projectmonkeychunkey',
    component: TeamFadiProjectMonkeychunkeyComponent,
    data: {
      title: "contactus | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   {
    path: 'projectfedex',
    component: TeamFadiProjectFedexComponent,
    data: {
      title: "contactus | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   {
    path: 'projectshan',
    component: TeamFadiProjectShanComponent,
    data: {
      title: "contactus | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
   {
    path: 'projectkia',
    component: TeamFadiProjectKiaComponent,
    data: {
      title: "contactus | Team Fadi",
      name: "Description",
      content: " Record keeping."
    }
   },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
