import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamFadiComponent } from './layouts/team-fadi/team-fadi.component';
//import { NavService } from './shared/service/nav.service';
import { TeamFadiIndexComponent } from './layouts/team-fadi-index/team-fadi-index.component';
import { TeamFadiAboutComponent } from './layouts/team-fadi-about/team-fadi-about.component';
import { TeamFadiProjectsComponent } from './layouts/team-fadi-projects/team-fadi-projects.component';
import { TeamFadiBlogsComponent } from './layouts/team-fadi-blogs/team-fadi-blogs.component';
import { TeamFadiClientsComponent } from './layouts/team-fadi-clients/team-fadi-clients.component';
import { TeamFadiContactusComponent } from './layouts/team-fadi-contactus/team-fadi-contactus.component';
import { TeamFadiServicesComponent } from './layouts/team-fadi-services/team-fadi-services.component';
import { TeamFadiProjectShoopComponent } from './layouts/team-fadi-project-shoop/team-fadi-project-shoop.component';
import { TeamFadiProjectMonkeychunkeyComponent } from './layouts/team-fadi-project-monkeychunkey/team-fadi-project-monkeychunkey.component';
import { TeamFadiProjectFedexComponent } from './layouts/team-fadi-project-fedex/team-fadi-project-fedex.component';
import { TeamFadiProjectShanComponent } from './layouts/team-fadi-project-shan/team-fadi-project-shan.component';
import { TeamFadiProjectKiaComponent } from './layouts/team-fadi-project-kia/team-fadi-project-kia.component';





export const routes: Routes = [


  {
    path: 'index',
    redirectTo: '',
    pathMatch: 'full',

  },
  {
   path: '',
   component: TeamFadiIndexComponent
 },
 {
  path: '',
  component: TeamFadiBlogsComponent
},
{
path: '',
component: TeamFadiClientsComponent
},
{
  path: '',
  component: TeamFadiContactusComponent
},
  {
    path: '',
    component: TeamFadiAboutComponent
  },
     {
    path: '',
    component: TeamFadiProjectsComponent
  },
  {
    path: '',
    component: TeamFadiServicesComponent
  },
  {
    path: '',
    component: TeamFadiProjectShoopComponent
  },
  {
    path: '',
    component: TeamFadiProjectMonkeychunkeyComponent
  },
  {
    path: '',
    component: TeamFadiProjectFedexComponent
  },
  {
    path: '',
    component: TeamFadiProjectShanComponent
  },
  {
    path: '',
    component: TeamFadiProjectKiaComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
