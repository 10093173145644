import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { Ng5SliderModule } from 'ng5-slider';
import { SharedModule } from '../shared/shared.module'
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { TeamFadiComponent } from './team-fadi/team-fadi.component';
import { TeamFadiIndexComponent } from './team-fadi-index/team-fadi-index.component';
import { TeamFadiAboutComponent } from './team-fadi-about/team-fadi-about.component';
import { TeamFadiBlogsComponent } from './team-fadi-blogs/team-fadi-blogs.component';
import { TeamFadiClientsComponent } from './team-fadi-clients/team-fadi-clients.component';
import { TeamFadiContactusComponent } from './team-fadi-contactus/team-fadi-contactus.component';
import { TeamFadiProjectsComponent } from './team-fadi-projects/team-fadi-projects.component';
import { TeamFadiServicesComponent } from './team-fadi-services/team-fadi-services.component';
import { TeamFadiProjectShoopComponent } from './team-fadi-project-shoop/team-fadi-project-shoop.component';
import { TeamFadiProjectMonkeychunkeyComponent } from './team-fadi-project-monkeychunkey/team-fadi-project-monkeychunkey.component';
import { TeamFadiProjectFedexComponent } from './team-fadi-project-fedex/team-fadi-project-fedex.component';
import { TeamFadiProjectShanComponent } from './team-fadi-project-shan/team-fadi-project-shan.component';
import { TeamFadiProjectKiaComponent } from './team-fadi-project-kia/team-fadi-project-kia.component';



@NgModule({
  declarations: [TeamFadiComponent,
    TeamFadiIndexComponent,
    TeamFadiAboutComponent,
    TeamFadiBlogsComponent,
    TeamFadiClientsComponent,
    TeamFadiContactusComponent,
    TeamFadiProjectsComponent,
    TeamFadiServicesComponent,
    TeamFadiProjectShoopComponent,
    TeamFadiProjectMonkeychunkeyComponent,
    TeamFadiProjectFedexComponent,
    TeamFadiProjectShanComponent,
    TeamFadiProjectKiaComponent,
  ],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    GalleryModule,
    LightboxModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    // Ng5SliderModule,
    NgxMasonryModule
  ],

  exports: [

  ],
})

export class LayoutsModule { }
