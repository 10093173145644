import { Component, OnInit } from '@angular/core';
import { blogClassicDB } from '../../shared/data/blog/blog-classic/column-no-sidebar';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-blogs',
    templateUrl: 'team-fadi-blogs.component.html',
    styleUrls: ['team-fadi-blogs.component.scss']
})
export class TeamFadiBlogsComponent implements OnInit {
    public blogData: any

    constructor() {
        this.blogData = blogClassicDB.column;
    }

    ngOnInit() {
    }



    // <!-- Navigation Start------------------------------------------------------------------------- -->
    // <!-- Navigation End--------------------------------------------------------------------------- -->
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- Header Start----------------------------------------------------------------------------- -->
    headercarousel = [
        {
          img: 'assets/images/team-fadi/header-01-01.jpg',
          tagLine1:"We Are Brand",
          tagLine2:" Architects",
          description1:"- Welcome to TEAM FADI – Your Partner in Success!",
        //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
        //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
        },
      ]
      
      headercarouselOptions= {
        items: 1,
        margin: 0,
        autoHeight: true,
        nav: false,
        autoplay: false,
        slideSpeed: 200,
        paginationSpeed: 400,
        loop: true,
      }
    // <!-- Header End-------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Footer Start----------------------------------------------------------------------------- -->
    // <!--  Footer End------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start-------------------------------------------------------------------------- -->
    // <!--  Copyright End---------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- ----------------------------The End-------------------------------------------------------- -->
}
