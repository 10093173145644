import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-layout-content',
  templateUrl: './breadcrumb-layout-content.component.html',
  styleUrls: ['./breadcrumb-layout-content.component.scss']
})
export class breadcrumbLayoutContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
