<!-- Navigation bar Start------------------------------------------------------------------- -->
<header class="gym loding-header nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(0 0 0 / 80%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="https://teamfadi.com/" class="d-inline-block m-r-auto">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="logo" class="img-fluid" style="width: 100px; margin-left: 150px;">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End--------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start--------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
            <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel">
                        <ng-template carouselSlide class="item">
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url('+ header.img +')'}" style="background-position: center; background-size: cover; object-fit: cover;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                            <h1>
                                                <span class="bold-text">{{header.tagLine1}}
                                                <br> &nbsp;&nbsp;&nbsp;&nbsp; {{header.tagLine2}} </span>
                                            </h1>
                                        </div>
                                        <div class="header-sub-text">
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description1}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description2}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description3}}</p>
                                        </div>
                                        <div class="link-horizontal">
                                            <ul class="justify-content-center">
                                                <li>
                                                    <a class="btn btn-default btn-gradient text-white"
                                                    style="padding-left: 50px; padding-right: 50px;">start now</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
                </ng-container>
                </owl-carousel-o>
              </div>
</section>
<!-- Header End----------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- About Start---------------------------------------------------------------------------- -->
<section class="gym format" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/team-fadi/about.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">about us</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para">- Our Story: Learn about our journey and commitment to excellence.</p>
                            <p class="p-light about-para">- Our Team: Meet the talented individuals behind our success.</p>
                            <p class="p-light about-para">- Our Values: Discover the principles that guide our work.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white"
                        style="padding-left: 50px; padding-right: 50px;" routerLink="about">read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About End------------------------------------------------------------------------------ -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- services start------------------------------------------------------------------------- -->
<section class="gym format bg-schedule" id="schedule"  [ngStyle]="{'background-image': 'url(assets/images/team-fadi/header-01.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2 class="text-white m-b-15">Over Services</h2>
                    </div>
                </div>
            </div>
            <div class="col-12">
                    <owl-carousel-o [options]="schedulecarouselOptions" class="default-dots gym-about-slider">
                            <ng-container *ngFor="let schedule of schedulecarousel">
                                <ng-template carouselSlide class="row">
                                   <div class="col-lg-5 item">
                                       <div class="center-text">
                                     <div>
                                      <div class="format-head-text">
                                        <h3 class="text-white about-font-header">{{schedule.tagLine2}}</h3>
                                      </div>
                                      <div class="format-sub-text">
                                        <p class="p-light about-para text-white" style="font-size: 18px; font-weight: 400;">{{schedule.description1}}</p>
                                      </div>
                                      <a class="btn btn-default btn-gradient text-white"
                                      style="padding-left: 50px; padding-right: 50px;" routerLink="services">learn more</a>
                                       </div>
                                       </div>
                                 </div>
                             </ng-template>
                         </ng-container>
                     </owl-carousel-o>
                 </div>
             </div>
         </div>
</section>
<router-outlet></router-outlet>
<!--services end---------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  project Start------------------------------------------------------------------------- -->
<section class="gym trainers">
    <div class="container overflow-hide">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before"><span>OUR PROJECTS</span></h6>
                        </div>
                        <div class="main-title">
                            <h2>PROJECTS WE DONE</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="trainercarouselOptions" class="trainers-slider">
                    <ng-container *ngFor="let trainer of trainercarousel">
                        <ng-template carouselSlide class="item">
                            <img [src]="trainer.img" alt="" class="img-fluid">
                            <div class="text-center trainers-info">
                                <h5 class="post text-center ">{{trainer.title}}</h5>
                                <a href="javascript:void(0)" class="btn btn-default btn-gradient text-white"
                                    style="padding-left: 50px; padding-right: 50px;" routerLink="projects">Explore ></a>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>

            </div>
        </div>
    </div>
</section>
<!-- project End---------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--success start--------------------------------------------------------------------------- -->
<section class="gym counter  bg-img3 bg1" [ngStyle]="{'background-image': 'url(assets/images/team-fadi/success.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-sm-3 col-6 counter-container" *ngFor="let data of counter">
                <div class="counters">
                    <img [src]="data.img" alt="icons" class="img-fluid counter-img" style="padding-top: 50px;">
                    <div class="counter-text">
                        <div class="count-number">
                            <h2 class="text-white counts"><span class="counter">{{ data.count }}</span></h2>
                        </div>
                        <div class="count-desc">
                            <h5 class="text-white">{{data.type}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--success end----------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  FAQ Start----------------------------------------------------------------------------- -->
<!-- <section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/team-fadi/about.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class=" center-text">
                    <div>
                        <section id="faq" class="saas1 faq testimonial-bg">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="faq-block">
                                            <div>
                                                <div class="format-small-text">
                                                    <h6 class="font-primary borders-before"><span>FAQ</span></h6>
                                                </div>
                                                <div class="format-head-text">
                                                    <h3 class="about-font-header gradient-text">Frequently Asked
                                                        Questions</h3>
                                                </div>
                                                <div class="accordion faq" id="accordionExample">
                                                    <div class="card">
                                                        <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                                            <ngb-panel id="static-1">
                                                                <ng-template ngbPanelTitle>
                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.
                                                                </ng-template>
                                                            </ngb-panel>
                                                            <ngb-panel id="static-2">
                                                                <ng-template ngbPanelTitle>

                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?

                                                                </ng-template>
                                                                <ng-template ngbPanelContent>

                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.

                                                                </ng-template>
                                                            </ngb-panel>
                                                            <ngb-panel id="static-3">
                                                                <ng-template ngbPanelTitle>
                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.
                                                                </ng-template>
                                                            </ngb-panel>
                                                            <ngb-panel id="static-4">
                                                                <ng-template ngbPanelTitle>
                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.
                                                                </ng-template>
                                                            </ngb-panel>
                                                            <ngb-panel id="static-5">
                                                                <ng-template ngbPanelTitle>
                                                                    <a>
                                                                        <div class="fa fa-angle-right rotate"></div>
                                                                    </a>
                                                                    Do I need to make a payment?
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    No one cares about products. People care about
                                                                    ideas. Is a product an idea?
                                                                    Noup. Is a brand? A good one is. No one cares about
                                                                    products. People care about ideas. Is a product an
                                                                    idea?
                                                                    Noup. Is a brand? A good one is.
                                                                </ng-template>
                                                            </ngb-panel>
                                                        </ngb-accordion>
                                                    </div>
                                                </div>
                                                <h6 class="link">Still have a question? Reach out to us:
                                                    <a>demo@123.com</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--  FAQ End------------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- client start--------------------------------------------------------------------------- -->
<section class="wedding saas1 brand-sliders">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before"><span>OUR PROJECTS</span></h6>
                        </div>
                        <div class="main-title">
                            <h2>Our Trust</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="brandCarouselOptions" class="brand-slider">
                    <ng-container *ngFor="let brand of brands">
                        <ng-template carouselSlide class="item">
                            <a href="javascript:void(0)">
                                <img [src]="brand.img" alt="" class="img-fluid wedding-brand" style="width: 100px; height: 100px;">
                            </a>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
        <div>
        </div>
    </div>
</section>
<!-- client End----------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start-------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="team fadi" class="img-fluid footer-logo" style="width: 80px; height: 80px;">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Address: A-44, Block-17, FB Area,</h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                            <h6 class="text-white para-address">Email: teamfadiofficial@gmail.com</h6>
                            <h6 class="text-white para-address">Contact No: 0300-2536441</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">services</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">projects</a>
                            </li>
                            <li class="">
                                <a routerLink="Clients">Clients</a>
                            </li>
                            <li class="">
                                <a routerLink="Contactus">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">Billboards</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Corporate Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Stall Fabrication</a>
                            </li>
                            <li class="">
                                <a routerLink="services"> Digital Marketing</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="projects">Complete Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Shop Fascia</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Confrence Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Stall Fabrication</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Copyright Start----------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- ---------------------------------------The End----------------------------------------- -->