<!-- Navigation bar Start------------------------------------------------------------------- -->
<header class="gym loding-header nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(0 0 0 / 90%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a class="d-inline-block m-r-auto" href="https://teamfadi.com/">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="logo" class="img-fluid" style="width: 100px; margin-left: 150px;">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End--------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start--------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
            <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel">
                        <ng-template carouselSlide class="item">
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url('+ header.img +')'}" style="background-position: center; background-size: cover; object-fit: cover;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                            <h1>
                                                <span class="bold-text">{{header.tagLine1}}
                                                <br> &nbsp;&nbsp;&nbsp;&nbsp; {{header.tagLine2}} </span>
                                            </h1>
                                        </div>
                                        <div class="header-sub-text">
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description1}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description2}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description3}}</p>
                                        </div>
                                        <div class="link-horizontal">
                                            <ul class="justify-content-center">
                                                <li>
                                                    <a class="btn btn-default btn-gradient text-white"
                                                    style="padding-left: 50px; padding-right: 50px;">start now</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
                </ng-container>
                </owl-carousel-o>
              </div>
</section>
<!-- Header End----------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- project 2 start------------------------------------------------------------------------ -->
<section class="wedding portfolio-section zoom-gallery">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title">
                    <div class="main-title">
                        <h2 class="gradient-text">Our Project</h2>
                    </div>
                    <!-- <div class="sub-title">
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus explicabo doloribus
                            numquam odio distinctio perspiciatis, ducimus vel laborum nostrum modi!</p>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 filter-section">
                <div class="filter-container isotopeFilters">
                    <ul class="list-inline filter">
                        <li [ngClass]="{active : activeFilter == 'all-img'}">
                            <a href="javascript:void(0)" (click)="filterImg('all-img')" data-filter="*" style="color: #ffd400; font-size: 25px ;">All </a>
                        </li>
                        <li [ngClass]="{active : activeFilter == 'Branding'}">
                            <a href="javascript:void(0)" (click)="filterImg('Branding')"
                                data-filter=".Branding" style="color: #ffd400">Branding And Design</a>
                        </li>
                        <li [ngClass]="{active : activeFilter == 'Fascia'}">
                            <a href="javascript:void(0)" (click)="filterImg('Fascia')" data-filter=".Fascia" style="color: #ffd400">Fascia</a>
                        </li>
                        <li [ngClass]="{active : activeFilter == 'StallFabrication'}">
                            <a href="javascript:void(0)" (click)="filterImg('StallFabrication')"
                                data-filter=".StallFabrication" style="color: #ffd400">Stall Fabrication</a>
                        </li>
                        <li [ngClass]="{active : activeFilter== 'Events'}">
                            <a href="javascript:void(0)" (click)="filterImg('Events')"
                                data-filter=".Events" style="color: #ffd400">Events</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- ================================================================================================== -->
        <!-- ==============================================ALL================================================= -->
        <ngx-masonry [options]="myOptions" #masonry class="masonry-grid m-layout margin_cls"
            *ngIf="activeFilter == 'all-img'">
         <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of  urlsAll">
                <img class="img-fluid" [src]="img">
         </div>
        </ngx-masonry>
        <!-- ====================================================================================================== -->
        <!-- ===========================================Branding=================================================== -->
        <ngx-masonry [options]="myOptions" class="masonry-grid m-layout margin_cls" *ngIf="activeFilter == 'Branding'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsBranding">
                <img class="img-fluid" [src]="img" routerLinkActive="active" routerLink="/projectshoop" tabindex="0">
            </div>
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                 *ngFor="let img of urlsBranding1">
                 <img class="img-fluid" [src]="img">
            </div>
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                  *ngFor="let img of urlsBranding2">
                  <img class="img-fluid" [src]="img" routerLinkActive="active" routerLink="/projectmonkeychunkey" tabindex="0">
            </div>
        </ngx-masonry>
        <!-- ==================================================================================================== -->
        <!-- ===============================================Fascia=============================================== -->
        <ngx-masonry [options]="myOptions" class="masonry-grid m-layout margin_cls" *ngIf="activeFilter == 'Fascia'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsFascia">
                <img class="img-fluid" [src]="img" routerLinkActive="active" routerLink="/projectfedex" tabindex="0">
            </div>
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
            *ngFor="let img of urlsFascia1">
            <img class="img-fluid" [src]="img">
        </div>
        </ngx-masonry>
        <!-- ===================================================================================================== -->
        <!-- ===========================================StallFabrication========================================== -->
        <ngx-masonry [options]="myOptions" class="masonry-grid m-layout margin_cls" *ngIf="activeFilter == 'StallFabrication'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsStallFabrication">
                <img class="img-fluid" [src]="img" routerLinkActive="active" routerLink="/projectshan" tabindex="0">
            </div>
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
            *ngFor="let img of urlsStallFabrication1">
            <img class="img-fluid" [src]="img">
        </div>
        </ngx-masonry>
        <!-- ====================================================================================================== -->
        <ngx-masonry [options]="myOptions" class="masonry-grid m-layout margin_cls" *ngIf="activeFilter == 'Events'">
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsEvents">
                <img class="img-fluid" [src]="img" routerLinkActive="active" routerLink="/projectkia" tabindex="0">
            </div>
            <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                  *ngFor="let img of urlsEvents1">
                  <img class="img-fluid" [src]="img">
           </div>
           <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
                *ngFor="let img of urlsEvents2">
                <img class="img-fluid" [src]="img" routerLinkActive="active" routerLink="/projectkia" tabindex="0">
           </div>
        </ngx-masonry>
        <!-- ======================================================================================================= -->
    </div>
</section>
<!-- project 2 end-------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start-------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="team fadi" class="img-fluid footer-logo" style="width: 80px; height: 80px;">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Address: A-44, Block-17, FB Area,</h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                            <h6 class="text-white para-address">Email: teamfadiofficial@gmail.com</h6>
                            <h6 class="text-white para-address">Contact No: 0300-2536441</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">services</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">projects</a>
                            </li>
                            <li class="">
                                <a routerLink="Clients">Clients</a>
                            </li>
                            <li class="">
                                <a routerLink="Contactus">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">Billboards</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Corporate Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Stall Fabrication</a>
                            </li>
                            <li class="">
                                <a routerLink="services"> Digital Marketing</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="projects">Complete Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Shop Fascia</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Confrence Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Stall Fabrication</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Copyright Start----------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by Team Fadi
                    </h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- --------------------------------------The End------------------------------------------ -->