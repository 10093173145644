<!-- Navigation bar Start--------------------------------------------------------------------------- -->
<header class="gym loding-header nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(0 0 0 / 90%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="https://teamfadi.com/" class="d-inline-block m-r-auto">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="logo" class="img-fluid" style="width: 100px; margin-left: 150px;">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End----------------------------------------------------------------------------- -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Header Start----------------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
            <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel">
                        <ng-template carouselSlide class="item">
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url('+ header.img +')'}" style="background-position: center; background-size: cover; object-fit: cover;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                            <h1>
                                                <span class="bold-text">{{header.tagLine1}}
                                                <br> &nbsp;&nbsp;&nbsp;&nbsp; {{header.tagLine2}} </span>
                                            </h1>
                                        </div>
                                        <div class="header-sub-text">
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description1}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description2}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description3}}</p>
                                        </div>
                                        <div class="link-horizontal">
                                            <ul class="justify-content-center">
                                                <li>
                                                    <a class="btn btn-default btn-gradient text-white"
                                                    style="padding-left: 50px; padding-right: 50px;">start now</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
                </ng-container>
                </owl-carousel-o>
              </div>
</section>
<!-- Header End------------------------------------------------------------------------------------- -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Billboards Start------------------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class=" offset-md-2 col-md-8">
                <div class="title title2">
                    <div class="sub-title">
                        <div class="">
                            <h2 class="title-text" style="margin-bottom: 100px;"> Our services</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/6.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Billboards / OOH</h3> 
                               <p class="font-primary borders-before"> <span>Look up and see your brand everywhere.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Out of home advertising is a medium which cannot be turned off, closed,
                                missed or minimized. This is the medium to reach your consumers on the
                                go. 7WIN360 is a leading outdoor advertising media planners and buyers.
                                We work directly with clients and agencies, and will provide outstanding
                                Out-of-Home planning, management and advertising campaigns. We deals
                                with traditional and digital billboard advertising, interactive, mobile, diverse
                                and roadside advertising. By working with all the independent and major
                                media owners across the nation, 7WIN360 ensures that every client is
                                provided with a customized outdoor advertising program and a fresh way
                                to engage with audiences on the best formats available.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-01.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Billboards End--------------------------------------------------------------------------------- -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--Shop Fascia Start------------------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-02.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/5.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Shop Fascia / Outdoor Branding</h3>
                                <p class="font-primary borders-before"> <span>Make your Business shine with new Signs.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Our team of techie talents have decades of combined experience
                                in designing & developing completely bespoke websites using WordPress, Shopify &
                                HubSpot. Whether you’re a startup or an established enterprise, we can sprinkle our
                                digital magic to conjure you up a beautiful website.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Shop Fascia End----------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Branding Start------------------------------------------------------------------------ -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/4.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Branding and Design</h3> 
                               <p class="font-primary borders-before"> <span>We'll make your brand stand out.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Your business is unique, as are your digital marketing needs.
                                Our in-house technical wizardry comprises of deep expertise in the whole digital
                                landscape. From SEO to PPC (and every acronym inbetween), nobody provides digital
                                marketing services like us.</p>
                            <p class="p-light about-para">Each business has a unique fingerprint, so we create
                                strategies and campaigns that are truly bespoke to you. Our in-house digital marketing
                                geniuses are dab hands in all channels, from PPC to CRO (and every acronym
                                inbetween).<br>
                                In short – nobody provides digital marketing services like us. Let us tailor a digital
                                marketing strategy that keeps your fingers on the pulse and fits you like a glove.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-03.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Branding End-------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////-->
<!--Content Creation Start----------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-04.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/3.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Content Creation</h3>
                                <p class="font-primary borders-before"> <span>Quality content that engages and converts.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">We’ve developed deep expertise in Search Engine Optimization,
                                and are a top-rated SEO agency. Reliable, organic traffic is an unmatched growth source,
                                and knowing how to achieve it is in our blood. Whether it’s on or off-site SEO, stop
                                your search right here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Content Creation End--------------------------------------------------------------------------- -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Corporate Branding Start----------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/2.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Corporate Branding / Events</h3>
                                <p class="font-primary borders-before"> <span>Make your ideas into reality.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">From Twitter to TikTok, and every platform, reel and piece of
                                content between the two – we’ve got you covered. Managing social media platforms and
                                content creation comes native to us, but we don’t stop there. We nurture your audience
                                into a meaningful community</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-05.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Corporate Branding End------------------------------------------------------------------------ -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--Stall Fabrication Start------------------------------------------------------------------------ -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-06.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/1.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Stall Fabrication </h3>
                                <p class="font-primary borders-before"> <span>A structure of Excellency. </span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">We’ve developed deep expertise in Search Engine Optimization,
                                and are a top-rated SEO agency. Reliable, organic traffic is an unmatched growth source,
                                and knowing how to achieve it is in our blood. Whether it’s on or off-site SEO, stop
                                your search right here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Stall Fabrication End-------------------------------------------------------------------------- -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Product Shoot Start---------------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/6.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Product Shoot</h3> 
                               <p class="font-primary borders-before"> <span>Bringing your product to the spotlight  .</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Out of home advertising is a medium which cannot be turned off, closed,
                                missed or minimized. This is the medium to reach your consumers on the
                                go. 7WIN360 is a leading outdoor advertising media planners and buyers.
                                We work directly with clients and agencies, and will provide outstanding
                                Out-of-Home planning, management and advertising campaigns. We deals
                                with traditional and digital billboard advertising, interactive, mobile, diverse
                                and roadside advertising. By working with all the independent and major
                                media owners across the nation, 7WIN360 ensures that every client is
                                provided with a customized outdoor advertising program and a fresh way
                                to engage with audiences on the best formats available.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-07.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Product Shoot End------------------------------------------------------------------------------ -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--Photography Start------------------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-08.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/5.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Life Style Photography</h3>
                                <p class="font-primary borders-before"> <span>Your life in still.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Our team of techie talents have decades of combined experience
                                in designing & developing completely bespoke websites using WordPress, Shopify &
                                HubSpot. Whether you’re a startup or an established enterprise, we can sprinkle our
                                digital magic to conjure you up a beautiful website.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Photography End--------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Video Shoot  Start------------------------------------------------------------------------ -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/4.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Video Shoot (Corporate)</h3> 
                               <p class="font-primary borders-before"> <span>We put your thoughts into motion.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">Your business is unique, as are your digital marketing needs.
                                Our in-house technical wizardry comprises of deep expertise in the whole digital
                                landscape. From SEO to PPC (and every acronym inbetween), nobody provides digital
                                marketing services like us.</p>
                            <p class="p-light about-para">Each business has a unique fingerprint, so we create
                                strategies and campaigns that are truly bespoke to you. Our in-house digital marketing
                                geniuses are dab hands in all channels, from PPC to CRO (and every acronym
                                inbetween).<br>
                                In short – nobody provides digital marketing services like us. Let us tailor a digital
                                marketing strategy that keeps your fingers on the pulse and fits you like a glove.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-09.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Video Shoot  End-------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Digital Video Start----------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-10.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/3.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Digital Video Commercial (DVC)</h3>
                                <p class="font-primary borders-before"> <span>Creation Strikes Back.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">We’ve developed deep expertise in Search Engine Optimization,
                                and are a top-rated SEO agency. Reliable, organic traffic is an unmatched growth source,
                                and knowing how to achieve it is in our blood. Whether it’s on or off-site SEO, stop
                                your search right here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Digital Video End------------------------------------------------------------------------------ -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Television Commercial Start-------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/2.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Television Commercial (TVC)</h3>
                                <p class="font-primary borders-before"> <span>We live and breathe videos</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">From Twitter to TikTok, and every platform, reel and piece of
                                content between the two – we’ve got you covered. Managing social media platforms and
                                content creation comes native to us, but we don’t stop there. We nurture your audience
                                into a meaningful community</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-11.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Television Commercial End--------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Printing Solutions Start---------------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-12.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/1.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Printing Solutions </h3>
                                <p class="font-primary borders-before"> <span>Perfect utilization of colors on desired materials.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">We’ve developed deep expertise in Search Engine Optimization,
                                and are a top-rated SEO agency. Reliable, organic traffic is an unmatched growth source,
                                and knowing how to achieve it is in our blood. Whether it’s on or off-site SEO, stop
                                your search right here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Printing End--------------------------------------------------------------------------- -->
<!--/////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Digital Marketing: Start--------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/2.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Digital Marketing</h3>
                                <p class="font-primary borders-before"> <span>From SEO to PPC, we excel in all aspects of digital marketing.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">From Twitter to TikTok, and every platform, reel and piece of
                                content between the two – we’ve got you covered. Managing social media platforms and
                                content creation comes native to us, but we don’t stop there. We nurture your audience
                                into a meaningful community</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-13.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Digital Marketing End------------------------------------------------------------------------- -->
<!--////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- Social Media Management Start----------------------------------------------------------------- -->
<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 hide-on-init" data-aos="fade-right"  [@fadeInLeft]="fadeInLeft" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/team-fadi/services/services-14.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/resume/service-exprience/1.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">Social Media Management </h3>
                                <p class="font-primary borders-before"> <span>Let us boost your online presence.</span> </p>
                            </div>
                        </div>
                        <div class="information">
                            <p class="p-light about-para">We’ve developed deep expertise in Search Engine Optimization,
                                and are a top-rated SEO agency. Reliable, organic traffic is an unmatched growth source,
                                and knowing how to achieve it is in our blood. Whether it’s on or off-site SEO, stop
                                your search right here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Social Media End----------------------------------------------------------------------------- -->
<!--///////////////////////////////////////////////////////////////////////////////////////////////-->
<!--  Footer Start-------------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="team fadi" class="img-fluid footer-logo" style="width: 80px; height: 80px;">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Address: A-44, Block-17, FB Area,</h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                            <h6 class="text-white para-address">Email: teamfadiofficial@gmail.com</h6>
                            <h6 class="text-white para-address">Contact No: 0300-2536441</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">services</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">projects</a>
                            </li>
                            <li class="">
                                <a routerLink="Clients">Clients</a>
                            </li>
                            <li class="">
                                <a routerLink="Contactus">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">Billboards</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Corporate Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Stall Fabrication</a>
                            </li>
                            <li class="">
                                <a routerLink="services"> Digital Marketing</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="projects">Complete Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Shop Fascia</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Confrence Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Stall Fabrication</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End------------------------------------------------------------------------------------ -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!--  Copyright Start------------------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End--------------------------------------------------------------------------------- -->
<!--/////////////////////////////////////////////////////////////////////////////////////////////////-->
<!-- -----------------------------------------The End----------------------------------------------- -->