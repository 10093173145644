import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-contactus',
    templateUrl: 'team-fadi-contactus.component.html',
    styleUrls: ['team-fadi-contactus.component.scss']
})
export class TeamFadiContactusComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }



    // <!-- Navigation Start-------------------------------------------------------------------------------------- -->
    // <!-- Navigation End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!-- Header Start-------------------------------------------------------------------------------------- -->
    headercarousel = [
        {
          img: 'assets/images/team-fadi/Banners/contact_us.jpg',
          tagLine1:"Contact US",
          // tagLine2:" Architects",
          description1:"- Welcome to TEAM FADI – Your Partner in Success!",
        //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
        //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
        },
      ]
      
      headercarouselOptions= {
        items: 1,
        margin: 0,
        autoHeight: true,
        nav: false,
        autoplay: false,
        slideSpeed: 200,
        paginationSpeed: 400,
        loop: true,
      }
    // <!-- Header End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Footer Start-------------------------------------------------------------------------------------- -->
    // <!--  Footer End---------------------------------------------------------------------------------------- -->
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // <!--  Copyright Start-------------------------------------------------------------------------------------- -->
    // <!--  Copyright End---------------------------------------------------------------------------------------- -->

}
