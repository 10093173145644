

<!--video2 section start-->
<section class="saas1 testimonial videos testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text m-b-0"><span class="text-uppercase">video 1</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-5 col-sm-12">
                <div class="center-content">
                    <div class="video w-100">
                        <a class="button center-content">
                            <img src="assets/images/saas1/testimonial-video-icon.png" (click)="openVerticallyCentered(content)" alt="video" class="img-fluid">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="testimonial-slider">
                    <owl-carousel-o [options]="testimonialcarouselOptions1" class="testimonial-carousel ">
                        <ng-container *ngFor="let slide of testimonialcarousel1">
                            <ng-template carouselSlide>
                    <div class="testimonial">
                        <div class="testimonial-box">
                            <img [src]="slide.quote" alt="dot">
                            <h6>{{slide.review}}</h6>
                        </div>
                    </div>
                    <div class="testi-profile">
                        <div class="media">
                            <img class="" [src]="slide.userImg"
                                 alt="profile-testimonial">
                            <div class="media-body">
                                <h5 class="mt-0">{{slide.userName}}</h5>
                                <h6>{{slide.position}}</h6>
                            </div>
                        </div>
                    </div>
                    </ng-template>
                    </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>
<!--video2 section end-->

<!--video1 section start-->
<section class="app1 videos bg-theme">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title title2 title-inner">
                        <div class="main-title">
                            <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span
                                    class="text-white white-border">video 2</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-12">
                    <div class="center-content">
                        <div class="video w-100">
                            <a class="button center-content">
                                <img src="assets/images/app_landing1/playl-1.png" (click)="openVerticallyCentered(content)" alt="video" class="img-fluid">
                            </a>
                            <ng-template #content let-modal>
                                <div class="modal-body video-modal">
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    <iframe class="mfp-iframe" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/dNIfsv1rKJo?autoplay=1"></iframe>
                                </div>
                              </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 offset-lg-1 col-md-7 col-sm-12 counters video-description">
                    <h3 class="m-b-30 text-white">Discover The New App</h3>
                    <div class="m-b-35">
                        <p class="p-light">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt
                            ut</p>
    
                        <p class="p-light">ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit
                            in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur.</p>
    
                        <p class="p-light">cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                            laborum.</p>
                    </div>
                    <div class="link-horizontal">
                        <ul>
                            <li>
                                <a class="icon-btn">
                                    <i class="fa fa-android center-content shadow" aria-hidden="true"></i>
                                    <h6 class="text-center text-white">android</h6>
                                </a>
                            </li>
                            <li class="borders-right p-0"></li>
                            <li>
                                <a class="icon-btn">
                                    <i class="fa fa-apple center-content shadow" aria-hidden="true"></i>
                                    <h6 class="text-center text-white">ios</h6>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--video1 section end-->
    

    <!--video4 section start-->
<section>
        <div class="app1 container">
            <div class="row">
                <div class="col-12">
                    <div class="title title2 title-inner">
                        <div class="main-title">
                            <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>video 3</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="agency video video-bg p-0" id="video" [ngStyle]="{'background-image': 'url(assets/images/agency/videos/video-background.png)'}">
            <div class="container">
                <div class="row">
                    <div class="col-12 p-0">
                        <div class="text-center">
                            <div class="d-inline-block">
                                <img src="assets/images/agency/videos/bg.png" alt="" class="img-fluid bg-video">
                                <div class="abs-center">
                                    <div>
                                        <div class="m-b-20">
                                            <a class="button">
                                                <img src="assets/images/agency/videos/icon.png"  (click)="openVerticallyCentered(content)" alt=""
                                                     class="img-fluid video-img">
                                            </a>
                                        </div>
                                        <div>
                                            <a  class="video w-100">
                                                <img src="assets/images/agency/videos/title-icon.png" alt=""
                                                     class="img-fluid m-b-20">
                                            </a>
                                            <div>
                                                <h3 class="video-heading text-white"> Amazing Videos &amp;
                                                    Presentations</h3>
                                                <h6 class="video-text text-white">We create. We Develop. We Inspire.</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
    <!--video4 section end-->

    
<!--video3 section start-->
<section class="music bg-video format" [ngStyle]="{'background-image': 'url(assets/images/music/video-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span
                                class="text-white white-border">video 4</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img src="assets/images/music/singer.png"  alt="" class="img-fluid">
            </div>
            <div class="col-md-6">
                <div class=" center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="gradient-text hash-video font-600">#video</h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header text-white">Anet Licker</h3>
                        </div>
                        <div class="format-sub-text">
                            <div class="sub-heading">
                                <h6 class="sub-small-text text-white">Performs in Slide Spring Tour</h6>
                            </div>
                            <p class="text-white sub-para">The page builder elements that we’ve created for you, make
                                the
                                website building process easy and fun.</p>
                        </div>
                        <div class="m-b-40">
                            <div class="link-horizontal">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0)" class="button icon-btn d-flex">
                                            <i class="fa fa-play video-icon center-content m-0" (click)="openVerticallyCentered(content)" aria-hidden="true"></i>
                                            <div class="watch-now center-content">
                                                <h6 class="watch text-white">Watch Now</h6>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex">
                            <h6 class="watch text-white">see all video<i class="fa fa-arrow-right m-l-15"
                                                                         aria-hidden="true"></i>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--video3 section end-->

