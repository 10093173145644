
<!--service app1 start-->
<section class="app1 services bg">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>service 1</span></h2>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="service service-overlay text-center" data-tilt="" data-tilt-max="6" data-tilt-speed="400"
                     data-tilt-perspective="500"
                     style="will-change: transform; transform: perspective(500px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);">
                    <div class="img-block">
                        <img src="assets/images/app_landing1/icons/1-1.png" alt="" class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Powerful System</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the ma- jority have
                            suffered.</p>

                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="service service-overlay text-center" data-tilt="" data-tilt-max="6" data-tilt-speed="400"
                     data-tilt-perspective="500"
                     style="will-change: transform; transform: perspective(500px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);">
                    <div class="img-block">
                        <img src="assets/images/app_landing1/icons/1-2.png" alt="" class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Personalization</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the ma- jority have
                            suffered.</p>

                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="service service-overlay text-center" data-tilt="" data-tilt-max="6" data-tilt-speed="400"
                     data-tilt-perspective="500"
                     style="will-change: transform; transform: perspective(500px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);">
                    <div class="img-block">
                        <img src="assets/images/app_landing1/icons/1-3.png" alt="" class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Ease Of Access</h4>
                        <p>There are many variations of passages of Lorem Ipsum available, but the ma- jority have
                            suffered.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--service app1 end-->

<!--service saas1 start-->
<section id="feature" class="saas1 service bg-light-inner">
    <div class="about-chat">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title title2 title-inner">
                        <div class="main-title">
                            <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>service 2</span></h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 service-container">
                    <div class="chat-box">
                        <img src="assets/images/saas1/feature/1.png" alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading"> Responsive <span class="theme-color">Layout</span>
                            </h4>
                            <hr>
                            <p>Responsive code that makes your landing page look good on all devices (desktops,
                                tablets, and phones). Created with mobile specialists</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 service-container">
                    <div class="chat-box">
                        <img src="assets/images/saas1/feature/1.png" alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading"> Responsive <span class="theme-color">Layout</span>
                            </h4>
                            <hr>
                            <p>Responsive code that makes your landing page look good on all devices (desktops,
                                tablets, and phones). Created with mobile specialists</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 service-container">
                    <div class="chat-box">
                        <img src="assets/images/saas1/feature/1.png" alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading"> Responsive <span class="theme-color">Layout</span>
                            </h4>
                            <hr>
                            <p>Responsive code that makes your landing page look good on all devices (desktops,
                                tablets, and phones). Created with mobile specialists</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 service-container">
                    <div class="chat-box">
                        <img src="assets/images/saas1/feature/1.png" alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading"> Responsive <span class="theme-color">Layout</span>
                            </h4>
                            <hr>
                            <p>Responsive code that makes your landing page look good on all devices (desktops,
                                tablets, and phones). Created with mobile specialists</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 service-container">
                    <div class="chat-box">
                        <img src="assets/images/saas1/feature/1.png" alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading"> Responsive <span class="theme-color">Layout</span>
                            </h4>
                            <hr>
                            <p>Responsive code that makes your landing page look good on all devices (desktops,
                                tablets, and phones). Created with mobile specialists</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 service-container">
                    <div class="chat-box">
                        <img src="assets/images/saas1/feature/1.png" alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading"> Responsive <span class="theme-color">Layout</span>
                            </h4>
                            <hr>
                            <p>Responsive code that makes your landing page look good on all devices (desktops,
                                tablets, and phones). Created with mobile specialists</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--service saas1 end-->

<!--service app2 start-->
<section class="app2 services">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>service 3</span></h2>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 service-container">
                <div class="service text-center">
                    <div class="img-block">
                        <img src="assets/images/app_landing2/service/2-1.png" alt=""
                             class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Fast and optimized</h4>
                        <p>Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant
                            and actionable.</p>

                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 service-container">
                <div class="service text-center">
                    <div class="img-block">
                        <img src="assets/images/app_landing2/service/2-2.png" alt=""
                             class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Well Documented</h4>
                        <p>Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant
                            and actionable.</p>

                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 service-container">
                <div class="service text-center">
                    <div class="img-block">
                        <img src="assets/images/app_landing2/service/2-3.png" alt=""
                             class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Easy Integration</h4>
                        <p>Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant
                            and actionable.</p>

                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 service-container">
                <div class="service text-center">
                    <div class="img-block">
                        <img src="assets/images/app_landing2/service/2-1.png" alt=""
                             class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Fast and optimized</h4>
                        <p>Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant
                            and actionable.</p>

                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 service-container">
                <div class="service text-center">
                    <div class="img-block">
                        <img src="assets/images/app_landing2/service/2-2.png" alt=""
                             class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Well Documented</h4>
                        <p>Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant
                            and actionable.</p>

                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 service-container">
                <div class="service text-center">
                    <div class="img-block">
                        <img src="assets/images/app_landing2/service/2-3.png" alt=""
                             class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">Easy Integration</h4>
                        <p>Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant
                            and actionable.</p>

                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<!--service app2 end-->

<section class="agency format service-bg" id="features" [ngStyle]="{'background-image': 'url(assets/images/agency/service/services-backgrondpng.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-4 offset-lg-1">
                <div class="center-text">
                    <div class="format-container">
                        <h6 class="borders-before text-uppercase">
                            <span>Our services</span>
                        </h6>
                        <div class="format-head-text">
                            <h2 class="about-font-header">Services we’re <span class="block-span">
                                            provided</span></h2>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt.</p>
                        </div>
                        <a href="#" class=" btn btn-default btn-gradient text-white">view more</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-8">
                <div class="row">
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection aos-init aos-animate" data-aos="fade-left" data-aos-duration="500">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/1.png"
                                                                        alt="">
                                    <h5>Finance Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection aos-init aos-animate" data-aos="fade-left" data-aos-duration="2000">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/2.png"
                                                                        alt="">
                                    <h5>Finance Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/3.png"
                                                                        alt="">
                                    <h5>Finance Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection aos-init aos-animate" data-aos="fade-left" data-aos-duration="3000">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/4.png"
                                                                        alt="">
                                    <h5>Finance Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/5.png"
                                                                        alt="">
                                    <h5>Finance Planning</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/agency/service/6.png"
                                                                        alt="">
                                    <h5>Finance Planning</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
