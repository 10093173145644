<section class="app1 download">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6" data-aos="fade-right" data-aos-duration="1500">
                <div class="center-content center-mobile">
                    <img src="assets/images/app_landing1/mobile.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="download-img d-flex">
                            <div class="set-relative">
                                <img src="assets/images/app_landing1/download/download.png" alt=""
                                     class="download-icon img-fluid">
                                <div class="set-abs elipse center-abs">
                                    <img src="assets/images/app_landing1/download/Ellipse.png" alt="">
                                </div>
                            </div>
                            <div class="center-img-content m-l-15">
                                <h3 class="m-b-5">download</h3>
                                <p>the unice</p>
                            </div>
                        </div>
                        <div class="information">
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                do eiusmod tempor unt ut labore et
                                dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud ercitation ullamco laboris nisi ut aliquip ex ea
                                commodo
                                consequat.Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur
                                sint
                                occaecat cupidatat non proident, sunt in culpa.</p>
                        </div>
                        <div class="link-horizontal">
                            <ul>
                                <li>
                                    <a class="icon-btn">
                                        <i class="fa fa-android center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">android</h6>
                                    </a>
                                </li>
                                <li class="borders-right p-0"></li>
                                <li>
                                    <a class="icon-btn">
                                        <i class="fa fa-apple center-content shadow" aria-hidden="true"></i>
                                        <h6 class="text-center font-primary">ios</h6>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>