import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'team-fadi-project-shoop',
    templateUrl: 'team-fadi-project-shoop.component.html',
    styleUrls: ['team-fadi-project-shoop.component.scss']
})
export class TeamFadiProjectShoopComponent {

    constructor() { }

    ngOnInit() {
    }
  
  
  
    // <!-- Navigation Start-------------------------------------------------------Navigation End---- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- Header Start----------------------------------------------------------------------------- -->
    headercarousel = [
      {
        img: 'assets/images/team-fadi/Banners/project.jpg',
        tagLine1:"Shan Foods",
        // tagLine2:" Architects",
        description1:"- Shoop Launch Event at Head Office!",
      //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
      //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
      },
    ]
    
    headercarouselOptions= {
      items: 1,
      margin: 0,
      autoHeight: true,
      nav: false,
      autoplay: false,
      slideSpeed: 200,
      paginationSpeed: 400,
      loop: true,
    }
    // <!-- Header End-------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- client start------------------------------------------------------------------------------- -->
    client = [
      {
        img: 'assets/images/team-fadi/branding/branding1.png',
      },
      {
        img: 'assets/images/team-fadi/branding/branding2.png',
      },
      {
        img: 'assets/images/team-fadi/branding/branding3.png',
      },
      {
        img: 'assets/images/team-fadi/branding/branding4.png',

      },
      {
        img: 'assets/images/team-fadi/branding/branding5.png',
      },
      {
        img: 'assets/images/team-fadi/branding/branding6.png',
      },
      
    ]
    // <!-- client End-------------------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Footer Start---------------------------------------------------------------Footer End---- -->
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start------------------------------------------------------------Copyright End- -->
}
