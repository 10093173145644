<!-- Navigation bar Start------------------------------------------------------------------- -->
<header class="gym loding-header nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(0 0 0 / 90%)'  }">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="https://teamfadi.com/" class="d-inline-block m-r-auto">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="logo" class="img-fluid" style="width: 100px; margin-left: 150px;">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End--------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start--------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
            <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel">
                        <ng-template carouselSlide class="item">
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url('+ header.img +')'}" style="background-position: center; background-size: cover; object-fit: cover;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                            <h1>
                                                <span class="bold-text">{{header.tagLine1}}
                                                <br> &nbsp;&nbsp;&nbsp;&nbsp; {{header.tagLine2}} </span>
                                            </h1>
                                        </div>
                                        <div class="header-sub-text">
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description1}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description2}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description3}}</p>
                                        </div>
                                        <div class="link-horizontal">
                                            <ul class="justify-content-center">
                                                <li>
                                                    <a class="btn btn-default btn-gradient text-white"
                                                    style="padding-left: 50px; padding-right: 50px;">start now</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
                </ng-container>
                </owl-carousel-o>
              </div>
</section>
<!-- Header End--------------------------------------------------------------------------- -->
<!-- ///////////////////////////////////////////////////////////////////////////////////// -->
<!-- About Start-------------------------------------------------------------------------- -->
<section class="gym format" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/team-fadi/about.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span style="color: #ffd500;">our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">about us</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para">- Our Story: Learn about our journey and commitment to excellence.</p>
                            <p class="p-light about-para">- Our Team: Meet the talented individuals behind our success.</p>
                            <p class="p-light about-para">- Our Values: Discover the principles that guide our work.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About End------------------------------------------------------------------------------ -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- what we do start ---------------------------------------------------------------------- -->
<section class="gym testimonial">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before" style="color: #ffd500;"><span>What We Do</span></h6>
                        </div>
                        <div class="main-title">
                            <h2 >Using technology to
                                our advantage, we
                                build intelligent brand
                                experiences for our
                                clients.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 offset-lg-2">
                <owl-carousel-o [options]="testimonialCarouselOptions" class=" testimonial-slider">
                    <ng-container *ngFor="let user of users1">
                        <ng-template carouselSlide class="item">
                            <div class="">
                                <div class="text-center testimonial-info">
                                    <p class="p-light testimonial-para" style="color: black;">Team Fadi is a full-service design & marketing agency. We are comprised
                                        of experienced strategists, creatives, technologists, designers, marketers,
                                        storytellers, and inventors. And together we form a passionate collective
                                        dedicated to creating the most meaningful and effective solutions for
                                        progressive brands.
                                        We consistently deliver results for our clients, by first gaining a deep
                                        understanding of their needs and target market, and then combining
                                        insight-driven strategy & ideation with the highest levels of execution.
                                        </p>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- what we do end ------------------------------------------------------------------------ -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- about2 section Start ------------------------------------------------------------------ -->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-6 ">
                <owl-carousel-o [options]="testimonialCarouselOptions1" class=" testimonial-slider">
                    <ng-container *ngFor="let user of users2">
                        <ng-template carouselSlide class="item">
                            <div class="">
                                <img [src]="user.img" alt="" class="img-fluid m-auto" style="width: 400px; height: 400px;">
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <div class="col-md-6">
                <div class=" center-text">
                    <div>
                        <div class="format-sub-text">
                            <div class="sub-heading">
                                <h4 class="team-fadi">1. Discover</h4>
                            </div>
                            <p class="p-light about-para">Everything begins with a detailed research and discovery phase.
                                We analyze market trends, evaluate the competitive landscape
                                and immerse ourselves in the client's brand.</p>
                            <div class="sub-heading">
                                <h4 class="team-fadi">2. Design</h4>
                            </div>
                            <p class="p-light about-para">Our approach to creative is structured yet organic. Finding the true
                                essence of a brand allows us to communicate and visualize an emotive
                                response. A delicate balance of form and function. Making Ideas Attract</p>
                                <div class="sub-heading">
                                    <h4 class="team-fadi">3. Execute</h4>
                                </div>
                                <p class="p-light about-para">Time to turn creative concepts into response driven tactics. Using a variety
                                    of mediums, we engage consumers and embrace them where they live.
                                    Finding ways to increase brand awareness, build relationships and drive
                                    sales.</p>
                                    <div class="sub-heading">
                                        <h4 class="team-fadi">4. Analyze</h4>
                                    </div>
                                    <p class="p-light about-para">At the completion of each campaign, we spend time understanding
                                        the results. We look at the metrics, find key insights and implement
                                        adjustments to make informed marketing decisions on future brand
                                        initiatives..</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about2 section end -------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start-------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="team fadi" class="img-fluid footer-logo" style="width: 80px; height: 80px;">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Address: A-44, Block-17, FB Area,</h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                            <h6 class="text-white para-address">Email: teamfadiofficial@gmail.com</h6>
                            <h6 class="text-white para-address">Contact No: 0300-2536441</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">services</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">projects</a>
                            </li>
                            <li class="">
                                <a routerLink="Clients">Clients</a>
                            </li>
                            <li class="">
                                <a routerLink="Contactus">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">Billboards</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Corporate Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Stall Fabrication</a>
                            </li>
                            <li class="">
                                <a routerLink="services"> Digital Marketing</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="projects">Complete Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Shop Fascia</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Confrence Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Stall Fabrication</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!--  Copyright Start----------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End------------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////////////// -->
<!-- ------------------------------------------The End-------------------------------------- -->