import { Component, OnInit } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeInLeft } from 'ng-animate';

@Component({

    selector: 'team-fadi-index',
    templateUrl: 'team-fadi-index.component.html',
    animations: [
        trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
            params: { timing: 3, delay: 1 }
        }))])
    ],

})
export class TeamFadiIndexComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
    //<!-- Navigation Start-------------------------------------------------------Navigation End---- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- header start----------------------------------------------------------------------------- -->
    headercarousel = [
        {
          img: 'assets/images/team-fadi/Banners/home_01.jpg',
          tagLine1:"We Are Brand",
          tagLine2:" Architects",
          description1:"- Welcome to TEAM FADI – Your Partner in Success!",
        //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
        //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
        },
        { 
          img: 'assets/images/team-fadi/Banners/home_02.jpg',
          tagLine1:"We Create",
          tagLine2:" Brands",
          description1:"Our team of experts is dedicated to creating tailored",
          description2:"marketing strategies that drive real, measurable results",
        //   description3:"dummy text ever since the 1500s, when an unknown printer took.",  
        },  
        {
            img: 'assets/images/team-fadi/Banners/home_03.jpg',
            tagLine1:"We Build",
            tagLine2:" Businesses",
            description1:"Explore our services and discover how we can ",
            description2:"elevate your brand's presence and profitability",
            // description3:"dummy text ever since the 1500s, when an unknown printer took.",  
          },
          { 
            img: 'assets/images/team-fadi/Banners/home_04.jpg',
            tagLine1:"We Establish",
            tagLine2:" Legaciesr",
            description1:"- We are a results-driven marketing agency",
            description2:"that specializes in helping businesses grow.",
            // description3:"dummy text ever since the 1500s, when an unknown printer took.",  
          },
      ]
      
      headercarouselOptions= {
        items: 1,
        margin: 0,
        autoHeight: true,
        nav: false,
        autoplay: true,
        slideSpeed: 200,
        paginationSpeed: 400,
        loop: true,
      }
    //<!-- header end ------------------------------------------------------------------------------  -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!-- About Start-----------------------------------------------------------------About End---- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- service start----------------------------------------------------------------------------- -->
    schedulecarousel = [
        { 
          tagLine2:"1.	Billboards / OOH",
          description1:"Look up and see your brand everywhere.",
        },
        { 
          tagLine2:"2.	Shop Fascia / Outdoor Branding",
          description1:"Make your Business shine with new Signs.",
        },
        { 
            tagLine2:"3.	Branding and Design",
            description1:"We'll make your brand stand out.",
          },
          { 
            tagLine2:"4.	Content Creation",
            description1:"Quality content that engages and converts.",
          },
          { 
            tagLine2:"5.	Corporate Branding / Events",
            description1:"Make your ideas into reality.",
          },
          { 
            tagLine2:"6.	Product Shoot: ",
            description1:"Bringing your product to the spotlight  ",
          },
          { 
            tagLine2:"7.	Video Shoot (Corporate",
            description1:"We put your thoughts into motion.",
          },
          { 
            tagLine2:"8.	Digital Marketing",
            description1:"From SEO to PPC, we excel in all aspects of digital marketing.",
          },
      ]
      
      schedulecarouselOptions= {
        items: 1,
        margin: 0,
        dots: true,
        nav: false,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
      }
    //<!-- service start----------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!--  project Start---------------------------------------------------------------------------- -->
    trainercarousel = [
        {
            img: "assets/images/team-fadi/projects/project_arynews.jpg",
            title: "Acrylic Logo Execution"
        },
        {
            img: "assets/images/team-fadi/projects/project_chunkymonkey.jpg",
            title: "Complete Branding"
        },
        {
            img: "assets/images/team-fadi/projects/project_dahlisweets.jpg",
            title: "Dahlisweets Shop Fascia"
        },
        {
            img: "assets/images/team-fadi/projects/project_fedex.jpg",
            title: "Fedex Shop Fascia"
        },
        {
            img: "assets/images/team-fadi/projects/project_kiamoters.jpg",
            title: "Confrence Branding"
        },
        {
            img: "assets/images/team-fadi/projects/project_shaanfoods.jpg",
            title: "Stall Fabrication"
        },
    ]
    trainercarouselOptions = {
        center: true,
        loop: true,
        dots: true,
        margin: 0,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        responsive: {
            0: {
                items: 2,
                margin: 10
            },
            576: {
                items: 3
            },
            768: {
                items: 4
            },
            1200: {
                items: 5
            }
        }
    }
    // <!--  project End----------------------------------------------------------------------------- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- success start----------------------------------------------------------------------------- -->
    counter = [
        {
          count:'100%',
          img:'assets/images/gym/counter/happy-icon.png',
          type:'Customer Satisfaction'
        },
        {
          count:'115',
          img:'assets/images/gym/counter/project-icon.png',
          type:'Projects'
        },
        {
          count:'8 Years',
          img:'assets/images/gym/counter/work-icon.png',
          type:'Years Experience'
        },
        {
          count:'98',
          img:'assets/images/gym/counter/award-icon.png',
          type:'Clients '
        }]
    //<!-- success end------------------------------------------------------------------------------ -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!-- clients start---------------------------------------------------------------------------- -->
    brands = [
        {
          img: 'assets/images/team-fadi//clientslogo/client-01.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-02.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-03.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-04.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-05.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-06.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-07.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-08.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-09.jpg',
        },
        {
          img: 'assets/images/team-fadi//clientslogo/client-10.jpg',
        },
    ]
    brandCarouselOptions = {
        items: 6,
        margin: 40,
        autoHeight: true,
        nav: false,
        dots: false,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        responsive: {
            0: {
                items: 1,
                margin: 40
            },
            360: {
                items: 2,
                margin: 20
            },
            480: {
                items: 3,
                margin: 30
            },
            768: {
                items: 5,
                margin: 30
            },
            1366: {
                items: 6
            }
        }
    }
    //<!-- clients end------------------------------------------------------------------------------ -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //<!--  Footer Start---------------------------------------------------------------Footer End--- -->
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // <!--  Copyright Start--------------------------------------------------------Copyright End--- -->
    //<!-- --------------------------------------The End-------------------------------------------- -->
}
