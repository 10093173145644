<!--breadcrumb section start-->
<section class="agency breadcrumb-section bg-dark video-sec breadcrumb-slider p-0">
  <video playsinline="" muted="" onplaying="this.controls=false" autoplay loop>
            <source src="assets/video/sea.mp4" type='video/mp4;'>
            <source src="assets/video/sea.mp4" type='video/webm;'>
            <source src="assets/video/sea.mp4" type='video/ogg;'>
        </video>
    <div class="container set-over">
        <div class="row">
            <div class="col-12"><h2 class="breadcrumb-text text-center">features with video-background</h2>
                <ul class="breadcrumb justify-content-center">
                    
                    <li><a href="#">breadcrumb style</a></li>
                    <li>video-background</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!--breadcrumb section end-->


<app-breadcrumb-layout-content></app-breadcrumb-layout-content>