import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boxshadow',
  templateUrl: './boxshadow.component.html',
  styleUrls: ['./boxshadow.component.scss']
})
export class BoxshadowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
