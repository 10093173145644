<!-- Login section -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Login</h3>
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" autocomplete="off" id="email" placeholder="Email" required="">
                        </div>
                        <div class="form-group">
                            <label for="review">Password</label>
                            <input type="password" class="form-control" autocomplete="off" id="review" placeholder="Enter your password"
                                   required="">
                        </div>
                        <a href="#" class="btn primary-btn btn-default radius-0">Login</a></form>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>New Customer</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">Create An Account</h6>
                    <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able
                        to order from our shop. To start shopping click register.</p><a href="#"
                                                                                        class="btn primary-btn btn-default radius-0">Create
                    an Account</a></div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->

