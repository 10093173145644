<!-- section start -->
<section class="agency blog blog-sec blog-sidebar blog-split sider">
    <div class="container">
        <div class="row  px-0">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-12 px-0">
                        <div class="blog-agency">
                            <div class="blog-contain">
                                <img src="assets/images/agency/blog/12-main.jpg" alt="" class="img-fluid">
                                <div class="img-container">
                                    <div class="blog-info bottom-0">
                                        <div class="d-flex m-b-20">
                                            <div class="d-flex center-text"><i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                                                <h6 class="m-r-25 font-blog">June 19, 2018</h6>
                                            </div>
                                            <div class="d-flex center-text"><i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                                                <h6 class="font-blog">Phonics ,Newyork</h6>
                                            </div>
                                        </div>
                                        <h5 class="blog-head font-600">Twice profit than before you</h5>
                                        <p class="para2">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                            text...............
                                        </p>
                                        <div class="btn-bottom  m-t-20"><a href="#" class="text-uppercase">read more</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 blog-sec blog-list" *ngFor="let blog of blogData">
                        <div class="blog-agency">
                            <div class="row blog-contain px-0">
                                <div class="col-xl-4 col-lg-5 col-md-6 p-0"  [ngClass]="blog.Id%2!=0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img [src]="blog.img" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-7 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <div class="m-b-20">
                                                    <div class="center-text"><i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                                                        <h6 class="m-r-25 font-blog">{{blog.date}}</h6>
                                                        <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                                                        <h6 class="font-blog">{{blog.type}}</h6>
                                                    </div>
                                                </div>
                                                <h5 class="blog-head font-600">{{blog.title}}</h5>
                                                <p class="para2">{{blog.content}}</p>
                                                <div class="btn-bottom m-t-20">
                                                    <a href="#" class="text-uppercase">{{blog.btn}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</section>
<!-- section and-->
