<!-- Navigation bar Start------------------------------------------------------------ -->
<header class="gym loding-header nav-abs custom-scroll nav-lg"
    [ngStyle]="{'background-color': 'rgb(0 0 0 / 90%)'  }" style="padding: 0rem 1rem;">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="https://teamfadi.com/" class="d-inline-block m-r-auto">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="logo" class="img-fluid" style="width: 100px; margin-left: 150px;">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////// -->
<!-- Header Start-----------------------------------------=------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
            <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel">
                        <ng-template carouselSlide class="item">
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url('+ header.img +')'}" style="background-position: center; background-size: cover; object-fit: cover;">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                            <h1>
                                                <span class="bold-text">{{header.tagLine1}}
                                                <br> &nbsp;&nbsp;&nbsp;&nbsp; {{header.tagLine2}} </span>
                                            </h1>
                                        </div>
                                        <div class="header-sub-text">
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description1}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description2}}</p>
                                            <p class=" p-light" style="font-size: 19px; font-weight: 400;">{{header.description3}}</p>
                                        </div>
                                        <div class="link-horizontal">
                                            <ul class="justify-content-center">
                                                <li>
                                                    <a class="btn btn-default btn-gradient text-white"
                                                    style="padding-left: 50px; padding-right: 50px;">start now</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
                </ng-container>
                </owl-carousel-o>
              </div>
</section>
<br><br>
<br><br>
<!-- Header End--------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////// -->
<!-- contact Start------------------------------------------------------------------ -->
<br><br>
<div class="contact-container">
    <div class="left-col">
    </div>
    <div class="right-col">
      <div class="theme-switch-wrapper">
      <label class="theme-switch" for="checkbox">
          <input type="checkbox" id="checkbox" />
          <div class="slider round"></div>
    </label>
  </div>
      <h1>Contact us</h1>
      <p>Planning to visit TEAM FADI? Get insider tips on where to go, things to do and find best deals for your next adventure.</p>
      <form id="contact-form" method="post">
        <label for="name">Full name</label>
    <input type="text" id="name" name="name" placeholder="Your Full Name" required>
        <label for="email">Email Address</label>
    <input type="email" id="email" name="email" placeholder="Your Email Address" required>
        <label for="message">Message</label>
    <textarea rows="6" placeholder="Your Message" id="message" name="message" required></textarea>

    <a href="javascript:void(0)"><button type="submit" id="submit" name="submit">Send</button></a>
    
  </form>
  <div id="error"></div>
  <div id="success-msg"></div>
    </div>
  </div>
  <br><br>
<br><br>
<!-- contact End-------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////// -->
<!--  Footer Start------------------------------------------------------------------ -->
<br><br>
<br><br>
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/team-fadi/logofadi-bg.png" alt="team fadi" class="img-fluid footer-logo" style="width: 80px; height: 80px;">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Address: A-44, Block-17, FB Area,</h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                            <h6 class="text-white para-address">Email: teamfadiofficial@gmail.com</h6>
                            <h6 class="text-white para-address">Contact No: 0300-2536441</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">services</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">projects</a>
                            </li>
                            <li class="">
                                <a routerLink="Clients">Clients</a>
                            </li>
                            <li class="">
                                <a routerLink="Contactus">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="services">Billboards</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Corporate Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="services">Stall Fabrication</a>
                            </li>
                            <li class="">
                                <a routerLink="services"> Digital Marketing</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a routerLink="projects">Complete Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Shop Fascia</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Confrence Branding</a>
                            </li>
                            <li class="">
                                <a routerLink="projects">Stall Fabrication</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End-------------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////// -->
<!--  Copyright Start--------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End----------------------------------------------------------------- -->
<!-- /////////////////////////////////////////////////////////////////////////////// -->
<!-- ----------------------------------The End-------------------------------------- -->