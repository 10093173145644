import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'team-fadi-clients',
  templateUrl: 'team-fadi-clients.component.html',
  styleUrls: ['team-fadi-clients.component.scss']
})
export class TeamFadiClientsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }



  // <!-- Navigation Start------------------------------------------------------------------------- -->
  // <!-- Navigation End--------------------------------------------------------------------------- -->
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // <!-- Header Start----------------------------------------------------------------------------- -->
  headercarousel = [
    {
      img: 'assets/images/team-fadi/Banners/clients.jpg',
      tagLine1:"Served Clients",
      // tagLine2:" Architects",
      description1:"- Welcome to TEAM FADI – Your Partner in Success!",
    //   description2:"typesetting industry. Lorem Ipsum has been the industry's standard",
    //   description3:"dummy text ever since the 1500s, when an unknown printer took.",
    },
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 200,
    paginationSpeed: 400,
    loop: true,
  }
  // <!-- Header End-------------------------------------------------------------------------------- -->
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //<!-- client start------------------------------------------------------------------------------- -->
  client = [
    {
      img: 'assets/images/team-fadi//clientslogo/client-01.jpg',
      title: 'ARY Didital',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-02.jpg',
      title: 'Pakistan Telecommunication Company Limited',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-03.jpg',
      title: 'Pepsi-Cola',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-04.jpg',
      title: 'Pfizer',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-05.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-06.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-07.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-08.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-09.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-10.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-11.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-12.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-13.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-14.jpg', 
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-15.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-16.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-17.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-18.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-19.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-20.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-21.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-22.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-23.jpg',
      title: 'dgdfg',
    },
    {
      img: 'assets/images/team-fadi//clientslogo/client-24.jpg',
      title: 'dgdfg',
    },

  ]
  // <!-- client End-------------------------------------------------------------------------------- -->
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //<!-- review start------------------------------------------------------------------------------- -->
  users = [
    {
      img: "assets/images/team-fadi/branding-3.png",
      name: "Kolan Motto",
      designation: "CEO, Therat",
      review: "It goes without saying that customer data sec urity and compliance is top of mind in most retail board rooms compliance is top."
    },
    {
      img: "assets/images/team-fadi/branding-4.png",
      name: "Kolan Motto",
      designation: "CEO, Therat",
      review: "It goes without saying that customer data sec urity and compliance is top of mind in most retail board rooms compliance is top."
    },
    {
      img: "assets/images/team-fadi/branding-5.png",
      name: "Kolan Motto",
      designation: "CEO, Therat",
      review: "It goes without saying that customer data sec urity and compliance is top of mind in most retail board rooms compliance is top."
    },
    {
      img: "assets/images/team-fadi/branding-1.png",
      name: "Kolan Motto",
      designation: "CEO, Therat",
      review: "It goes without saying that customer data sec urity and compliance is top of mind in most retail board rooms compliance is top."
    }
  ]
  testimonialCarouselOptions = {
    items: 3,
    margin: 65,
    nav: true,
    dots: false,
    navText: ['<img src="assets/images/agency/testimonial/left.png">', '<img src="assets/images/agency/testimonial/right.png">'],
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      575: {
        items: 2,
        margin: 10
      },
      991: {
        items: 2,
        margin: 30
      },
      1199: {
        items: 3,
        margin: 30
      }
    }
  }
  //<!-- review end--------------------------------------------------------------------------------- -->
  // <!--  Footer Start----------------------------------------------------------------------------- -->
  // <!--  Footer End------------------------------------------------------------------------------- -->
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  // <!--  Copyright Start-------------------------------------------------------------------------- -->
  // <!--  Copyright End---------------------------------------------------------------------------- -->

}
