<header class="app1 nav-abs custom-scroll">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>


<!-- header section Start-->

<section class="app1 header" id="home">
    <div class="app1-header bg" [ngStyle]="{'background-image': 'url(assets/images/app_landing1/header-1-bg.png)'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-7">
                    <div class="center-text">
                        <div>
                            <div class="header-text">
                                <h1>Showcase your app with unice</h1>
                            </div>
                            <div class="header-sub-text">
                                <p class="text-white p-light">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting
                                    industry. Lorem Ipsum has been</p>
                            </div>
                            <div class="link-horizontal">
                                <ul>
                                    <li>
                                        <a class="icon-btn">
                                            <i class="fa fa-android center-content" aria-hidden="true"></i>
                                            <h6 class="text-center text-white">android</h6>
                                        </a>
                                    </li>
                                    <li class="borders-right p-0"></li>
                                    <li>
                                        <a class="icon-btn">
                                            <i class="fa fa-apple center-content" aria-hidden="true"></i>
                                            <h6 class="text-center text-white">ios</h6>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rotate">
                    <img alt="" src="assets/images/app_landing1/preview_movie.png" class="img-fluid" data-tilt
                         data-tilt-max="3" data-tilt-speed="400" data-tilt-perspective="500">

                </div>
                <div class="squares">
                    <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-1 set-abs">
                    <img alt="" src="assets/images/app_landing1/Ellipse.png" class="img-fluid img-2 set-abs">
                </div>
                <div class="animation-circle-inverse">
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- header section end-->
