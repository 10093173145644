export class blogClassicDB {
    static column = [
        {
            Id: 1,
            img: 'assets/images/team-fadi/seo.webp',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 2,
            img: 'assets/images/team-fadi/branding.webp',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 3,
            img: 'assets/images/team-fadi/digital.webp',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 4,
            img: 'assets/images/team-fadi/social.webp',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 5,
            img: 'assets/images/team-fadi/graphic.webp',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 6,
            img: 'assets/images/team-fadi/branding-3.png',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 1,
            img: 'assets/images/team-fadi/branding-5.png',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 2,
            img: 'assets/images/team-fadi/branding.webp',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 3,
            img: 'assets/images/team-fadi/branding-4.png',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 4,
            img: 'assets/images/team-fadi/branding-2.png',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 5,
            img: 'assets/images/team-fadi/branding-1.png',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
        {
            Id: 6,
            img: 'assets/images/team-fadi/branding-3.png',
            date:'June 19, 2018',
            type:'Phonics ,Newyork',
            title:'Twice profit than before you',
            content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenbook...............',
            btn:'read more'
        },
    ]
}