<!--component html goes here -->
<!-- Navigation bar Start-------------------------------------------------------------------------------------- -->
<header class="gym loding-header nav-abs custom-scroll">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navigation bar End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!-- Header Start-------------------------------------------------------------------------------------- -->
<section class="gym header" id="home">
    <div class="header5-content">
        <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
            <ng-container *ngFor="let header of headercarousel" class="scroll">
                <ng-template carouselSlide class="item">
                    <div class="gym-header bg bg-scroll"
                        [ngStyle]="{'background-image': 'url(assets/images/team-fadi/header-02.jpg)'}">

                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="center-text">
                                        <div class="text-center">
                                            <div class="header-text">
                                                <h1><span
                                                        class="bold-text">{{header.tagLine1}}</span>{{header.tagLine2}}
                                                </h1>
                                            </div>
                                            <div class="header-sub-text">
                                                <p class="text-white p-light">{{header.description}}</p>
                                            </div>
                                            <div class="link-horizontal">
                                                <ul class="justify-content-center">
                                                    <li>
                                                        <a class=" btn btn-default"><span>About US
                                                                <sup></sup></span></a>
                                                    </li>
                                                    <li>
                                                        <a class=" btn btn-default">Brochure</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>

<script src="https://unpkg.com/scrollreveal"></script>

<script>
    const scrollRevealOption = {
        distance: "50px",
        origin: "bottom",
        duration: 1000,
    }

    ScrollReveal().reveal(".header .header5-content .bg-scroll", {
        ...scrollRevealOption,
    });

    ScrollReveal().reveal(".header .header5-content .scroll", {
        ...scrollRevealOption,
        delay: 1000,
    });

    ScrollReveal().reveal(".btn", {
        ...scrollRevealOption,
        delay: 1500,
    });
</script>
<!-- Header End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!-- About Start-------------------------------------------------------------------------------------- -->
<section class="gym format" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/team-fadi/about.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">about us</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para">Lorem Ipsum is simply dummy text of the printing and
                                typesetting
                                industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s.</p>
                            <p class="p-light about-para">When an unknown printer took a galley of type and scrambled it
                                to make
                                a type specimen
                                book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum has been the industry.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default primary-btn">read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

  <!--services start-->
  <section class="resume services bg-pink">
    <div class="container">
        <div class="row">
            <div class=" offset-md-2 col-md-8">
                <div class="title title2">
                    <h6 class="font-primary borders main-text text-uppercase"><span>details</span></h6>
                    <div class="sub-title">
                        <div class="">
                            <h2 class="title-text">services & experience</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-6" *ngFor="let data of service">
                <div class="service text-center bg-white">
                    <div class="img-block">
                        <img [src]="data.img" alt="" class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">{{data.type}}</h4>
                        <p>Unlike team chat apps, Flow is designed to help your team stay focused by keeping relevant
                            and actionable.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--services start-->






<!--  Trainer Start-------------------------------------------------------------------------------------- -->
<section class="gym trainers">
    <div class="container overflow-hide">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before"><span>OUR PROJECTS</span></h6>
                        </div>
                        <div class="main-title">
                            <h2>PROJECTS WE DONE</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="trainercarouselOptions" class="trainers-slider">
                    <ng-container *ngFor="let trainer of trainercarousel">
                        <ng-template carouselSlide class="item">
                            <img [src]="trainer.img" alt="" class="img-fluid">
                            <div class="text-center trainers-info">
                                <!-- <h3 class="m-b-10">Robbines</h3> -->
                                <!-- <div class="socials-lists">
                                    <ul class="socials-horizontal justify-content-center">
                                        <li>
                                            <a href="#">
                                                <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i class="fa fa-google center-content" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i class="fa fa-instagram center-content" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div> -->
                                <a href="javascript:void(0)" class="btn btn-default primary-btn">Read More</a>
                            </div>

                        </ng-template>
                    </ng-container>
                </owl-carousel-o>

            </div>
        </div>
    </div>
</section>
<!--  Trainer End---------------------------------------------------------------------------------------- -->







<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!-- Schdule Start-------------------------------------------------------------------------------------- -->
<!-- <section class="gym format bg-schedule" id="schedule" [ngStyle]="{'background-image': 'url(assets/images/gym/bg.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2 class="text-white m-b-15">classes schedule</h2>
                    </div>
                    <img src="assets/images/logo/l5-title-bottom.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="schedulecarouselOptions" class="default-dots gym-about-slider">
                    <ng-container *ngFor="let schedule of schedulecarousel">
                        <ng-template carouselSlide class="row">
                            <div class="col-lg-5 item">
                                <div class="center-text">
                                    <div>
                                        <div class="format-small-text">
                                            <h6 class="text-white borders-before"><span>{{schedule.tagLine1}}</span>
                                            </h6>
                                        </div>
                                        <div class="format-head-text">
                                            <h3 class="text-white about-font-header">{{schedule.tagLine2}}</h3>
                                        </div>
                                        <div class="format-sub-text">
                                            <p class="p-light about-para text-white">{{schedule.description1}}/p>
                                            <p class="p-light about-para text-white">{{schedule.description2}}</p>
                                        </div>
                                        <ul class="icon-collection">
                                            <li class="about-icon">
                                                <a href="#" class="center-content"><img
                                                        src="assets/images/gym/icons/2.png" alt=""
                                                        class="img-fluid icons"></a>
                                            </li>
                                            <li class="about-icon">
                                                <a href="#" class="center-content"><img
                                                        src="assets/images/gym/icons/1.png" alt=""
                                                        class="img-fluid icons"></a>
                                            </li>
                                            <li class="about-icon">
                                                <a href="#" class="center-content"><img
                                                        src="assets/images/gym/icons/3.png" alt=""
                                                        class="img-fluid icons"></a>
                                            </li>
                                        </ul>
                                        <a class="btn btn-default back-white m-t-45">learn more</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
 
            </div>
        </div>
    </div>
</section> -->
<!-- Schdule End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!--  Counter Start-------------------------------------------------------------------------------------- -->
<!-- <section class="gym counter  bg-img3 bg1" [ngStyle]="{'background-image': 'url(assets/images/gym/counter/bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-sm-3 col-6 counter-container" *ngFor="let data of counter">
                <div class="counters">
                    <img [src]="data.img" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <div class="count-number">
                            <h2 class="text-white counts"><span class="counter">{{ data.count }}</span></h2>
                        </div>
                        <div class="count-desc">
                            <h5 class="text-white">{{data.type}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--  Counter End---------------------------------------------------------------------------------------- -->

<!--about start-->
<section class="resume about" id="about">
    <div class="container">
        <div class="title title2">
            <div class="row">
                <div class=" offset-md-2 col-md-8">
                    <h6 class="font-primary borders main-text text-uppercase"><span>WHY US</span></h6>
                    <div class="sub-title">
                        <div class="">
                            <h2 class="title-text">TEAM FADI SUCCESS</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-6"  *ngFor="let data of about">
                <div class="text-center radial">
                    <div data-label="95%" class="radial-bar radial-bar-primary">
                    </div>
                    <h4 class="color-light m-t-15">{{data.name}}</h4>
                    <div class="border-container">
                        <div class="borders-bottom"></div>
                    </div>
                    <div>
                        <h4>{{data.exp}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center m-t-50">
            <a class="btn btn-default primary-btn">know more now</a>
        </div>
    </div>
  </section>
  <!--about end-->

<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!--  Testimonial Start-------------------------------------------------------------------------------------- -->
<!-- <section class="gym testimonial"
    [ngStyle]="{'background-image': 'url(assets/images/gym/testimonial/testimonial-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before text-white"><span>OUR PROGRESS</span></h6>
                        </div>
                        <div class="main-title">
                            <h2 class="text-white">what people say</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 offset-lg-2">
                <owl-carousel-o [options]="testimonialCarouselOptions" class=" testimonial-slider">
                    <ng-container *ngFor="let user of users">
                        <ng-template carouselSlide class="item">
                            <div class="">
                                <img [src]="user.img" alt="" class="img-fluid m-auto">
                                <div class="text-center testimonial-info">
                                    <p class="p-light testimonial-para">Lorem Ipsum is simply dummy text of the printing
                                        and
                                        typesetting industry. Lorem
                                        Ipsum
                                        has been the industry's standard dummy text ever since the 1500s, when an
                                        unknown
                                        printer took a galley of type and scrambled it to.</p>
                                    <div class="testimonial-name">
                                        <h5 class="text-white name">Alan Lakor</h5>
                                    </div>
                                    <div class="testimonial-post">
                                        <h6 class="text-white post">CEO OF SC.</h6>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section> -->
<!--  Testimonial End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!--  Pricing Start-------------------------------------------------------------------------------------- -->
<!-- <section class="gym pricing set-relative" id="plan">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before"><span>OUR PROGRESS</span></h6>
                        </div>
                        <div class="main-title">
                            <h2>price plan</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="pricingcarouselOptions" class="price-margin pricing-slider">
                    <ng-container *ngFor="let price of pricing">
                        <ng-template carouselSlide class="item">
                            <div class="price-container price-margin hover-overlay shadows bg-white text-center">
                                <div class="price-feature-container set-relative">
                                    <div class="feature-text">
                                        <span class="flaticon-{{price.icon}} feature-icon"></span>
                                        <h4 class="feature-text-heading bold text-uppercase">{{price.package}}</h4>
                                        <hr class="set-border">
                                    </div>
                                    <div class="price-features">
                                        <h5 class="price-feature text-center">{{price.feature1}}</h5>
                                        <h5 class="price-feature text-center">{{price.feature2}}</h5>
                                        <h5 class="price-feature text-center">{{price.feature3}}</h5>
                                    </div>
                                    <div class="price-value">
                                        <h6 class="price text-center">$<span class="large">{{price.price}}</span>/month
                                        </h6>
                                    </div>

                                    <a href="#" class="btn btn-default back-white">{{price.btn}}</a>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section> -->
<!--  Pricing End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!--  BMI Start-------------------------------------------------------------------------------------- -->
<!-- <section class="gym format bg-cal-bmi" id="bmi"
    [ngStyle]="{'background-image': 'url(assets/images/gym/calculat-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-6 ">
                <div class=" center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="borders-before text-white"><span>our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="text-white about-font-header">calculate your BMI</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para text-white">Lorem Ipsum is simply dummy text of the printing
                                and
                                typesetting
                                industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                        <div class="link-horizontal">
                            <ul>
                                <li class="">
                                    <div class="checkbox">
                                        <label class="text-white center-text"><input type="checkbox" value="">Metric
                                            Units</label>
                                    </div>
                                </li>
                                <li class="about-icon">
                                    <div class="checkbox">
                                        <label class="text-white center-text"><input type="checkbox" value="">Imperial
                                            Units</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="link-horizontal m-t-40 m-b-40">
                            <ul>
                                <li>
                                    <a class="btn btn-default primary-btn transparent">Weight/Kg</a>
                                </li>
                                <li>
                                    <a class="btn btn-default primary-btn transparent">Height/Cm</a>
                                </li>
                            </ul>
                        </div>
                        <a class="btn btn-default back-white">CALCULATE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 offset-lg-1 col-md-6">
                <div class="center-text">
                    <table class="calculate-bmi">
                        <tr>
                            <td>BMI</td>
                            <td>Weight Status</td>
                        </tr>
                        <tr>
                            <td>Below 18.5</td>
                            <td>Underweight</td>
                        </tr>
                        <tr>
                            <td>18.5 - 24.9</td>
                            <td>Normal</td>
                        </tr>
                        <tr>
                            <td>25 - 29.9</td>
                            <td>Overweight</td>
                        </tr>
                        <tr>
                            <td>30 and Above</td>
                            <td>Obese</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--  BMI End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!--  Brand Start-------------------------------------------------------------------------------------- -->
<section class="gym brand-slider">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before"><span>OUR CLIENTS</span></h6>
                        </div>
                        <div class="main-title">
                            <h2>TEAM FADI CLIENTS</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="brandcarouselOptions">
                    <ng-container *ngFor="let brand of brands">
                        <ng-template carouselSlide class="item">
                            <a href="javascript:void(0)">
                                <img [src]="brand.img" alt="" class="img-fluid">
                            </a>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!--  Brand End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!--  Workout Start-------------------------------------------------------------------------------------- -->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/gym/about.png" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class=" center-text">
                    <div>
                        <section id="faq" class="saas1 faq testimonial-bg">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="faq-block">
                                            <div>
                                                <div class="format-small-text">
                                                    <h6 class="font-primary borders-before"><span>FAQ</span></h6>
                                                </div>
                                                <div class="format-head-text">
                                                    <h3 class="about-font-header gradient-text">Frequently Asked Questions</h3>
                                                </div>
                                                <!-- <h3 class="frequent-text">Frequently Asked Questions</h3>
                                                <h6>Our users are impatient. They're probably distracted too. Keep it simple and beautiful, fun
                                                    and
                                                    functional. Clean aesthetics supported by a strong concept is what we stand for.</h6> -->
                                                <div class="accordion faq" id="accordionExample">
                                                    <div class="card">
                                                            <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                                                    <ngb-panel id="static-2">
                                                                            <ng-template ngbPanelTitle>              
                                                                                   <a>
                                                                                       <div class="fa fa-angle-right rotate"></div>
                                                                                   </a>
                                                                                   Do I need to make a payment?
                                                                                  </ng-template>
                                                                      <ng-template ngbPanelContent>
                                                                                    No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is.
                                                                    </ng-template>
                                                                    </ngb-panel>
                                                                    <ngb-panel id="static-2">
                                                                            <ng-template ngbPanelTitle>
                                                                                    
                                                                                   <a>
                                                                                       <div class="fa fa-angle-right rotate"></div>
                                                                                   </a>
                                                                                   Do I need to make a payment?
                                                                               
                                                                                  </ng-template>
                                                                      <ng-template ngbPanelContent>
                                                                            
                                                                                    No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is.
                                                                               
                                                                    </ng-template>
                                                                    </ngb-panel>
                                                                    <ngb-panel id="static-3">
                                                                            <ng-template ngbPanelTitle>
                                                                                   <a>
                                                                                       <div class="fa fa-angle-right rotate"></div>
                                                                                   </a>
                                                                                   Do I need to make a payment?
                                                                                  </ng-template>
                                                                      <ng-template ngbPanelContent>
                                                                                    No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is.
                                                                    </ng-template>
                                                                    </ngb-panel>
                                                                    <ngb-panel id="static-4">
                                                                            <ng-template ngbPanelTitle>
                                                                                   <a>
                                                                                       <div class="fa fa-angle-right rotate"></div>
                                                                                   </a>
                                                                                   Do I need to make a payment?
                                                                                  </ng-template>
                                                                      <ng-template ngbPanelContent>
                                                                                    No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is.
                                                                    </ng-template>
                                                                    </ngb-panel>
                                                                    <ngb-panel id="static-5">
                                                                            <ng-template ngbPanelTitle>
                                                                                   <a>
                                                                                       <div class="fa fa-angle-right rotate"></div>
                                                                                   </a>
                                                                                   Do I need to make a payment?
                                                                                  </ng-template>
                                                                      <ng-template ngbPanelContent>
                                                                                    No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                                                    Noup. Is a brand? A good one is.
                                                                    </ng-template>
                                                                    </ngb-panel>
                                                                </ngb-accordion>
                                                    </div>
                                                 </div>
                                                <h6 class="link">Still have a question? Reach out to us: <a>demo@123.com</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <div class="faq-img-block">
                                            <img src="assets/images/saas1/faq-img.png" class="img-fluid" alt="faq-person">
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </section>








                        
                        <!-- <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>our Contact</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">CONTACT US</h3>
                        </div>
                        <div class="format-sub-text">
                            <div class="sub-heading">
                                <h4>1. Trx Workout</h4>
                            </div>
                            <p class="p-light about-para">Lorem Ipsum is simply dummy text of the printing and
                                typesetting
                                industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s.</p>
                            <div class="sub-heading">
                                <h4>2. Posture Correction</h4>
                            </div>
                            <p class="p-light about-para">When an unknown printer took a galley of type and scrambled it
                                to make
                                a type specimen
                                book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum has been the industry.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default primary-btn">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--  Workout End---------------------------------------------------------------------------------------- -->

<!--  Footer Start-------------------------------------------------------------------------------------- -->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">TEAM FADI</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/1.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">TEAM FADI</h6>
                            <h6 class="text-white para-address">Office No# 123, Second Floor, </h6>
                            <h6 class="text-white para-address">Karachi, Pakistan.</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Team footer-headings</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">about</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">services</a>
                            </li>
                            <li class="">
                                <a href="#">projects</a>
                            </li>
                            <li class="">
                                <a href="#">Clients</a>
                            </li>
                            <li class="">
                                <a href="#">Contact US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Services-02</a>
                            </li>
                            <li class="">
                                <a href="#">Services-03</a>
                            </li>
                            <li class="">
                                <a href="#">Services-04</a>
                            </li>
                            <li class="">
                                <a href="#">Services-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white"> Projects</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Projects-01</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Projects-02</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-03</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-04</a>
                            </li>
                            <li class="">
                                <a href="#">Projects-05</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
<!--  Footer End---------------------------------------------------------------------------------------- -->
<!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
<!--  Copyright Start-------------------------------------------------------------------------------------- -->
<div class="gym copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right op-text">Copyright ©2023 by <i
                            class="fa fa-heart" aria-hidden="true"></i>
                        Team Fadi</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Copyright End---------------------------------------------------------------------------------------- -->